<tui-island class=" ">
  <p class="tui-island__category">Файл</p>
  <h3 class="tui-island__title">{{fileLabel ?? "Любой файл"}}</h3>

  <div class="fileIcon">.{{fileExt ?? "pdf"}}</div>
  <small>{{fileName ?? "nonamefile"}}</small>
  <BR>
  <a href="{{fileFullLink}}" *ngIf="fileFullLink" target="_blank">Скачать</a>
  <span *ngIf="!fileFullLink">Нет файла</span>
</tui-island>
