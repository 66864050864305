import { Component } from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent {
  apiHost: string = environment.API_URL;

  constructor() {
    console.log(this.apiHost);
  }
}
