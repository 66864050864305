<tui-island class=" " *ngIf="item!=null">

  <p>
Уведомления <b  style="opacity: 0.5;">( {{list.length}} )</b>
  </p>

  <div *ngIf=" list.length==0">
    Нет уведомлений
  </div>

  <div *ngFor="let inner of list">


    <tui-island class="mb-1    ">

      <small> {{inner.createdAt | date }}</small>

      <BR>
      <span>{{inner.title}}</span>    <b> [ {{inner.type ? "Смс" : "Почта"}} ]</b>
      <BR> <b> [ {{inner.isSuccesfull ? "Доставлено" : "Ошибка отправки"}} ] </b>

      <BR>
      <small>{{inner.content}}</small>

    </tui-island>

  </div>

</tui-island>
