<tui-theme-night></tui-theme-night>


<ng-template
  [tuiDialogOptions]="{size: 'page'}"
  [(tuiDialog)]="open"
>

  <div class="row" style="background: #2b2d31; height: 100vh; margin: 0px;">
    <div class="col-3"></div>

    <div class="col-2 p-4 ">

      <a *ngFor="let item of tabs" (click)="currentTab=item;"
         [ngClass]="{'tabItem-active': currentTab === item}"
         class="tabItem d-block">{{ item }}</a>
    </div>

    <div class="col" style="background: #313338;">
      <h1>{{currentTab}}</h1>

      <form [formGroup]="forms[currentTab]"  >
        <div class=" pb-3   " *ngFor="let item of GetCurrentFileds()">

          <ng-container *ngIf="item.options!=null">
            <tui-select

              [formControlName]="item.key"
            >
              {{ item.label }}
              <tui-data-list-wrapper
                *tuiDataList
                emptyContent="No results found"
                size="xs"
                [items]="GetOptionsFromItem(item)   "

              ></tui-data-list-wrapper>
            </tui-select>
          </ng-container>

          <ng-container *ngIf="item.options==null">
            <tui-input

              [formControlName]="item.key"
            >
              <small>{{ item.label }}</small>
            </tui-input>
          </ng-container>

          <small>{{ item.description }}</small>
        </div>
        <button (click)="SaveTab(currentTab)" tuiButton >Save</button>
      </form>
    </div>

    <div class="col-3" style="background: #313338;"></div>

  </div>


</ng-template>
