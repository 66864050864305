<div class="container" style="padding-top:10%;">
  <div class="row">

    <div class="col-6" style="padding-top: 15%; color: #fff; font-size: 15px;">


      <a class="navbar-brand   mb-2 " href="/" style="xtext-align: center; display: block;     padding-top: 1px;">

        <svg width="236" height="104" viewBox="0 0 236 104" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_55_9)">
            <path d="M47.9319 5.75281V49H40.0342L21.2191 21.7805H20.9024V49H11.7588V5.75281H19.7832L38.4504 32.9512H38.8305V5.75281H47.9319ZM55.4864 49V5.75281H72.5488C75.8289 5.75281 78.6234 6.37927 80.9321 7.6322C83.2409 8.87106 85.0006 10.5956 86.2113 12.8058C87.4361 15.002 88.0485 17.536 88.0485 20.4079C88.0485 23.2797 87.4291 25.8138 86.1902 28.0099C84.9514 30.2061 83.1564 31.9165 80.8054 33.1413C78.4685 34.3661 75.6388 34.9784 72.3165 34.9784H61.4413V27.6509H70.8383C72.598 27.6509 74.048 27.3482 75.1884 26.7429C76.3427 26.1235 77.2015 25.2718 77.7646 24.1878C78.3418 23.0897 78.6304 21.8297 78.6304 20.4079C78.6304 18.9719 78.3418 17.719 77.7646 16.6491C77.2015 15.5651 76.3427 14.7274 75.1884 14.1362C74.034 13.5308 72.5699 13.2282 70.7961 13.2282H64.63V49H55.4864ZM109.318 49H93.9876V5.75281H109.445C113.795 5.75281 117.54 6.6186 120.679 8.35018C123.819 10.0677 126.233 12.5383 127.922 15.7622C129.626 18.986 130.477 22.8433 130.477 27.3342C130.477 31.8391 129.626 35.7105 127.922 38.9484C126.233 42.1863 123.804 44.6711 120.637 46.4026C117.484 48.1342 113.711 49 109.318 49ZM103.131 41.1657H108.938C111.641 41.1657 113.915 40.687 115.759 39.7297C117.617 38.7584 119.011 37.2591 119.94 35.2319C120.883 33.1906 121.355 30.558 121.355 27.3342C121.355 24.1385 120.883 21.5271 119.94 19.4998C119.011 17.4726 117.624 15.9804 115.78 15.0231C113.936 14.0658 111.662 13.5871 108.959 13.5871H103.131V41.1657Z" fill="white"/>
            <path d="M9.52072 118V54.4807H22.9503V82.4875H23.7878L46.646 54.4807H62.743L39.1713 82.9217L63.0221 118H46.9562L29.5566 91.8851L22.9503 99.9491V118H9.52072ZM69.7369 54.4807H86.2991L103.792 97.1577H104.536L122.029 54.4807H138.591V118H125.564V76.6566H125.037L108.599 117.69H99.7287L83.2906 76.5015H82.7633V118H69.7369V54.4807Z" fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0_55_9">
              <rect width="236" height="104" fill="white"/>
            </clipPath>
          </defs>
        </svg>


      </a>

      Управление, настройки и разработка

    </div>
    <div class="col-6" style="max-width: 560px;">
      <tui-island style="padding: 30px 55px;border-radius: 7px;">

        <app-auth-form></app-auth-form>
      </tui-island>

    </div>
  </div>
</div>
