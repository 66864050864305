import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {User} from "../../../../shared/interfaces";
import {NotificationModel, Project} from "../../../contracts/ContractsAdmin";
import {EasyValidatorForm} from "../../../../services/EasyValidatorForm";
import {ErrorPushService} from "../../../../shared/error-push/ErrorPushService";
import {EasyApi} from "../../../../services/EasyApi";
import {Router} from "@angular/router";

@Component({
  selector: 'app-admin-addone-user-notifys',
  templateUrl: './admin-addone-user-notifys.component.html',
  styleUrls: ['./admin-addone-user-notifys.component.scss']
})
export class AdminAddoneUserNotifysComponent {

  public item: User | null = null;

  @Input()
  set itemInput(value: User | null) {
    if (value !== this.item) {
      this.item = value;
      this.Render();
    }
  }

  public list: NotificationModel[] = [];

  constructor(
    private easyValidatorForm: EasyValidatorForm,
    private errorPushService: ErrorPushService,
    private cdr: ChangeDetectorRef,
    private apiService: EasyApi,
    private router: Router
  ) {


  }


  async Render() {
    var response = await this.apiService.Get<NotificationModel[]>(`/api/admin/AdminNotification/ByAccount/` + (this.item?.id));

    this.list = [];
    console.log(response);

    if (response.value) {
      this.list = response.value;
    }

    this.cdr.detectChanges();
  }
}
