import { Component } from '@angular/core';

@Component({
  selector: 'app-reaction-from-chat',
  templateUrl: './reaction-from-chat.component.html',
  styleUrls: ['./reaction-from-chat.component.scss']
})
export class ReactionFromChatComponent {

}
