
<app-mini-layout>
  <div class="text-center p-4">

    <h1 class="mb-4">Оплата не выполнена</h1>
    <h5 class="text-muted mb-4">Ничего страшного!</h5>
    <p class="lead">
      К сожалению, ваша попытка оплаты не удалась. Однако, мы выражаем вам благодарность за ваше намерение и усилия. Пожалуйста, попробуйте еще раз или обратитесь в нашу службу поддержки, если у вас возникли какие-либо вопросы.
    </p>
    <a href="/" tuiButton appearance="primary" class="btn btn-primary mt-3">Перейти на главную</a>
  </div>
</app-mini-layout>
