import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ErrorPushComponent} from "./error-push.component";

@Injectable({
  providedIn: 'root'
})
export class ErrorPushService {
  componentPush: ErrorPushComponent | null = null;



  showError(error: string) {


    if (this.componentPush) {
      this.componentPush.showError(error);
    } else {
      console.log("Не успел прогрузится компонент ошибок " + error);
      console.log("ErrorPushService ISSET " + error);

    }
    //this.errorSubject.next(error);
  }
}
