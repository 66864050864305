import {ErrorPushService} from "./ErrorPushService";
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {TuiAlertService} from '@taiga-ui/core';

@Component({
  selector: 'app-error-push',
  templateUrl: './error-push.component.html',
  //styleUrls: ['./error-push.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPushComponent {
  constructor(private errorService: ErrorPushService,
              @Inject(TuiAlertService) protected readonly alert: TuiAlertService,
  ) {

    errorService.componentPush = this;

  }



  public showError(errorMessage: string) {

    this.alert
      .open(errorMessage, {
        label: "Ошибка",
        autoClose: true,
        status: "error"
      })
      .subscribe();
    console.log("ErrorPushComponent ISSET " + errorMessage);
  }
}
