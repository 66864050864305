<tui-island class=" " *ngIf="item!=null">

  <tui-loader class="loader"
              [inheritColor]="true"
              [overlay]="true"
              [showLoader]="IsLoading">

    <h3>
      Реестры <b style="opacity: 0.5;">( {{list.length}} )</b>
    </h3>


    <form [formGroup]="testForm" (ngSubmit)="Submit()" class="mb-2">
      <tui-input
        formControlName="searchInput"
        [tuiTextfieldLabelOutside]="true"
        tuiTextfieldPostfix="      Нажмите Enter"
        [tuiTextfieldSize]="'m'"

        [tuiTextfieldIconLeft]="'tuiIconSearch'"
      >
        Введите название или ид
      </tui-input>
      <tui-error
        formControlName="searchInput"
        [error]="[] | tuiFieldError | async"
      ></tui-error>
    </form>

    <div class="row p-3" style=" font-weight: bold; opacity: 0.6;">
      <div class="col-2">
        Реестр
      </div>
      <div class="col">
        Сумма
      </div>
      <div class="col">
        Дата создания
      </div>
      <div class="col">
        Дата исполнения
      </div>
      <div class="col">

      </div>
    </div>


    <div *ngFor="let element of list">

      <tui-island class="mb-1 tui-table__tr_cursor_pointer  ">

        <div class="row " style="padding-left: 12px; font-size: 14px;">


          <div class="col-2 p-0">
            {{element.name}}
            <BR> <small>   {{element.id  }}</small>
          </div>

          <div class="col">
            {{ element.payAmount  | tuiFormatNumber }} ₽
            <BR>

            <small> {{ element.taxAmount  | tuiFormatNumber }} ₽  налог</small>
          </div>


          <div class="col">
            {{ element.createdAt  | date }}
          </div>


          <div class="col">
            {{ element.excitedAt  | date }}
            <div *ngIf="!element.excitedAt">Не исполнен</div>
          </div>



          <div class="col  ">
            <a (click)="OnClick(element)" tuiLink>Перейти</a>
          </div>
        </div>
      </tui-island>

    </div>

  </tui-loader>
</tui-island>
