import {ChangeDetectorRef, Component, ElementRef, Injectable, Input, Output, ViewChild} from '@angular/core';
import {EasyApi} from "../../../services/EasyApi";
import {ChatControllerComponent} from "../chat-controller/chat-controller.component";
import {ResponseFormat, User} from "../../../shared/interfaces";
import {Message, TargetChat} from "../ChatModels";
import {TuiScrollbarComponent} from "@taiga-ui/core";
import {ChatLinker} from "../ChatLinker";

@Component({
  selector: 'app-history-chat',
  templateUrl: './history-chat.component.html',
  styleUrls: ['./history-chat.component.scss']
})

@Injectable({
  providedIn: 'root'
})
export class HistoryChatComponent {
  @ViewChild(TuiScrollbarComponent, {read: ElementRef})
  private readonly scrollBar?: ElementRef<HTMLElement>;

  public targetChat: any | null = null;

  constructor(
    private chatLinker: ChatLinker,
    private api: EasyApi,
    private cdr: ChangeDetectorRef
  ) {
    chatLinker.historyChatComponent = this;

  }

  public messages: Message[] = [];
  selectd?: Message | null = null;
  public contactAccount?: User | null = null;

  public UpdateHistory() {
    if (!this.contactAccount) return;
    this.GetHistory(this.contactAccount);
  }

  public AddMessage(msg: Message) {
    this.messages.push(msg);
    this.cdr.detectChanges();

    this.ScrollDown();
    this.cdr.detectChanges();
  }


  public GetTimeCreated(msg: Message) {
    var d = new Date(msg.createdAt);

    //console.log(d);
    //let v = Math.floor(msg.createdAt.getTime() / 1000);

    //console.log(v);
    return d.getTime();
  }

  public ScrollDown() {
    //console.log("ScrollDown");
    if (!this.scrollBar) return;
    const {nativeElement} = this.scrollBar;

    nativeElement.scrollTop = nativeElement.scrollHeight;

    this.cdr.detectChanges();
  }

  ClickMessage(selected: Message) {
    this.selectd = selected;
  }

  async GetHistory(contactAccount: User) {

    this.targetChat = {
      ReceiverUserId: contactAccount.id,
    };
    this.GetHistoryByTarget();
  }

  async GetHistoryByTarget() {

    let data = this.targetChat;

    let result: ResponseFormat<any> = await this.api.Post<any>("/api/Chat/History", data, true, true);
    this.messages = result.value.messages ?? [];
    this.cdr.detectChanges();

    this.ScrollDown();
    this.cdr.detectChanges();
  }

}
