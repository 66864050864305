<app-mini-layout>
  <h1>Демо сгиналов</h1>

  <div class="col-12 p-3">
  <app-user-profile-button></app-user-profile-button>
  </div>
  <button (click)="SendSignal()" class="w-100" tuiButton>Отправить сигнал</button>


</app-mini-layout>
