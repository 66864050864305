import { Component } from '@angular/core';

@Component({
  selector: 'app-privat-police-page',
  templateUrl: './privat-police-page.component.html',
  styleUrls: ['./privat-police-page.component.scss']
})
export class PrivatPolicePageComponent {
  currentDomain:string  = window.location.hostname;

}
