<tui-island class=" " *ngIf="item!=null">

  <tui-loader class="loader"
              [inheritColor]="true"
              [overlay]="true"
              [showLoader]="IsLoading">

  <p>
    Статус платформы <b  style="opacity: 0.5;"> [  {{item.platformContractStatus | PlatformStatusPipe }} ]</b>
  </p>

  <p style="font-size: 0.8em; opacity: 0.9;">
    Договор платформы - это контракт между клиентом и сервисом. Клиент отправляет документ, а админ меняет статус компании клиента.
  </p>



  <tui-hosted-dropdown [content]="content">
    <button
      tuiButton
      type="button"
      [iconRight]="arrow"
    >
      {{item.platformContractStatus | PlatformStatusPipe }}
    </button>
  </tui-hosted-dropdown>

  <ng-template #content>
    <tui-data-list role="menu">

        <a
          *ngFor="let opt of statusOptions"
          role="menuitemradio"
          tuiOption
          (click)="SelectedAny(opt)"

        >
          {{ opt | PlatformStatusPipe}}
          <tui-svg
            *ngIf="item.platformContractStatus == opt"
            src="tuiIconCheck"
          ></tui-svg>
        </a>
    </tui-data-list>
  </ng-template>

<BR>
<BR>
    <hr>
    <BR>
    <span *ngIf="item.platformContractFileId==null">Договор не приложен</span>

    <app-file-link-downloader fileExt="pdf" fileLabel="Документ договора" [fileFullLink]="item.platformContractFileId" ></app-file-link-downloader>
  </tui-loader>
</tui-island>
