import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ChatLinker} from "../../plugins/chat/ChatLinker";
import {User} from "../../shared/interfaces";

@Component({
  selector: 'app-chat-page',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.scss']
})
export class ChatPageComponent implements OnInit {
  private userId: string | null = null;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.userId = this.route.snapshot.params["userId"];

      if (!this.chatLinker.contactsChatComponent) return;

      if (this.chatLinker.historyChatComponent) {

        //let selected:User = this.chatLinker.contactsChatComponent.selectd;
        this.chatLinker.historyChatComponent.contactAccount = this.chatLinker.contactsChatComponent.selectd;
        this.chatLinker.historyChatComponent.UpdateHistory();
      }

      if (this.chatLinker.historyChatComponent && this.chatLinker.chatboxFormChatComponent) {
        this.chatLinker.chatboxFormChatComponent.targetChat = {
          GroupId: null,
          ReceiverUserId: this.userId,
          SubGroupId: null
        }
      }

    });
  }


  constructor(
    private route: ActivatedRoute,
    private chatLinker: ChatLinker,
  ) {
  }
}
