import {ChangeDetectorRef, Component} from '@angular/core';
import {Router} from "@angular/router";
import {EasyApi} from "../../services/EasyApi";
import {ResponseFormat, User} from "../../shared/interfaces";
import {interval} from "rxjs";
import {TuiBooleanHandler} from "@taiga-ui/cdk";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  constructor(
    private router: Router,
    private api: EasyApi,
    private cdr: ChangeDetectorRef
  ) {
    this.GetContacts();
  }


  public currentTab: string = "none";


  public tabs: string[] = [];
  public constructs: ProfileConstrucItem[] = [];
  open: any = true;

  GetOptionsFromItem(item: ProfileConstrucItem) {
    if (item.options == null) return [];
    const values = Object.values(item.options);
    //console.log(values);
    return values;
  }

  public forms: { [key: string]: FormGroup } = {};


  GetCurrentFileds() {
    return this.constructs.filter(construct => construct.tab === this.currentTab);
  }

  async GetContacts() {
    let result: ResponseFormat<any> = await this.api.Get<any>("/api/Profile/Get", true);

    if (!result.value) return;

    console.log("GetContacts");
    console.log(result);



    for (let i = 0; i < result.value.length; i++) {

      for (const key in result.value[i]) {

        const value: ProfileConstrucItem = result.value[i][key];
        value.key = key;


        let tab: string = value.tab;

        if (this.tabs.indexOf(tab) < 0) {
          this.forms[tab] = new FormGroup({});
          this.tabs.push(tab);
        }
        if (this.currentTab=="none") this.currentTab = tab;


        let fc = new FormControl(4, Validators.required);
        this.forms[tab].addControl(key, fc);

        console.log(key, value);
        this.constructs.push(value);
      }
    }

    console.log(this.forms);
    this.cdr.detectChanges();
  }

  SaveTab(currentTab: string) {
    let valuesCast: any = {};

    for (const key of Object.keys(this.forms[currentTab].value)) {
      const val = this.forms[currentTab].value[key];

      valuesCast[key] = val;

      const itemConstruct = this.constructs.filter(item => item.key == key)[0];
      if (!itemConstruct) continue;
      if (!itemConstruct.options) continue;

      valuesCast[key] = Object.keys(itemConstruct.options).find(key2 => itemConstruct.options[key2] === val) || null;

    }

    console.log(valuesCast);
  }


}


export interface ProfileConstrucItem {
  key: string,
  tab: string,
  label: string,
  value: any,
  description?: string,
  options?: any,
}
