import {NgModule, OnInit} from '@angular/core';
import {EvolonaCoreModules} from "./EvolonaCoreModules";
import {
  TuiComboBoxModule,
  TuiFilterByInputPipeModule,
  TuiStringifyContentPipeModule,
  TuiTabsModule
} from "@taiga-ui/kit";
import { ElementPlanComponent } from './plugins/plan/element-plan/element-plan.component';
import { PlansPageComponent } from './plugins/plan/plans-page/plans-page.component';
import { UsersAdminComponent } from './admin/users-admin/users-admin.component';
import { AdminLayoutComponent } from './admin/layouts/admin-layout/admin-layout.component';
import { UserAdminComponent } from './admin/user-admin/user-admin.component';
import { ProjectsAdminComponent } from './admin/projects-admin/projects-admin.component';
import { ProjectAdminComponent } from './admin/project-admin/project-admin.component';
import {TuiFormatDatePipeModule, TuiFormatNumberPipeModule, TuiFormatPhonePipeModule} from "@taiga-ui/core";
import { AuthTryAdminAddoneComponent } from './admin/addons/users/auth-try-admin-addone/auth-try-admin-addone.component';
import { AdminAddoneUserProjectsComponent } from './admin/addons/users/admin-addone-user-projects/admin-addone-user-projects.component';
import { AdminAddoneUserBanComponent } from './admin/addons/users/admin-addone-user-ban/admin-addone-user-ban.component';
import { AdminAddoneUserNotifysComponent } from './admin/addons/users/admin-addone-user-notifys/admin-addone-user-notifys.component';
import { AdminAddoneProjectBaseComponent } from './admin/addons/projects/admin-addone-project-base/admin-addone-project-base.component';
import { AdminAddoneUserFilesComponent } from './admin/addons/users/admin-addone-user-files/admin-addone-user-files.component';
import {FormatPipeExample, PlatformStatusPipe} from "./admin/PipeTransforms/PipesProject";
import { AdminAddoneProjectPlatformStatusComponent } from './admin/addons/projects/admin-addone-project-platform-status/admin-addone-project-platform-status.component';
import {TuiCurrencyPipeModule, TuiFormatCardModule} from "@taiga-ui/addon-commerce";
import { FileLinkDownloaderComponent } from './shared/file-link-downloader/file-link-downloader.component';
import { ExamplesBaseSearchAdminComponent } from './admin/example/examples-base-search-admin/examples-base-search-admin.component';
import { AdminAddoneProjectConterpartysComponent } from './admin/addons/projects/admin-addone-project-conterpartys/admin-addone-project-conterpartys.component';
import { AdminAddoneProjectReestrsComponent } from './admin/addons/projects/admin-addone-project-reestrs/admin-addone-project-reestrs.component';
import { TeamsSearchAdminComponent } from './admin/team/teams-search-admin/teams-search-admin.component';
import { TeamsWeightAdminComponent } from './admin/team/teams-weight-admin/teams-weight-admin.component';
import { TeamsMemberElementAdminComponent } from './admin/team/teams-member-element-admin/teams-member-element-admin.component';
import {
  MemberTypeNameNumberTeamPipe,
  MemberTypeNameTeamPipe,
  PermissionNameTeamPipe
} from "./admin/pipes/team-pipes/team-pipes.component";

@NgModule({
  declarations: [
    ...EvolonaCoreModules.declarations,
    ElementPlanComponent,
    PlansPageComponent,
    UsersAdminComponent,
    AdminLayoutComponent,
    UserAdminComponent,
    ProjectsAdminComponent,
    ProjectAdminComponent,
    AuthTryAdminAddoneComponent,
    AdminAddoneUserProjectsComponent,
    AdminAddoneUserBanComponent,
    AdminAddoneUserNotifysComponent,
    AdminAddoneProjectBaseComponent,
    AdminAddoneUserFilesComponent,
    ExamplesBaseSearchAdminComponent,
    AdminAddoneProjectPlatformStatusComponent,
    FileLinkDownloaderComponent,
    AdminAddoneProjectConterpartysComponent,
    AdminAddoneProjectReestrsComponent,
    TeamsSearchAdminComponent,
    TeamsWeightAdminComponent,
    TeamsMemberElementAdminComponent,
    PermissionNameTeamPipe,
    MemberTypeNameTeamPipe,
    MemberTypeNameNumberTeamPipe,

  ],


  imports: [
    ...EvolonaCoreModules.imports,
    TuiStringifyContentPipeModule,
    TuiFormatPhonePipeModule,
    TuiFormatDatePipeModule,
    FormatPipeExample,
    PlatformStatusPipe,
    TuiComboBoxModule,
    TuiCurrencyPipeModule,
    TuiFormatCardModule,
    TuiTabsModule,
    TuiFormatNumberPipeModule,

  ],

  providers: [
    ...EvolonaCoreModules.providers,

  ],

  bootstrap: [
    ...EvolonaCoreModules.bootstrap

  ],
})

export class AppModule   {


}
