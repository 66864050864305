import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',

  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./auth.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AuthComponent   {


}
