import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {Counterparty, PlatformProjectContractStatus, Project} from "../../../contracts/ContractsAdmin";
import {EasyValidatorForm} from "../../../../services/EasyValidatorForm";
import {ErrorPushService} from "../../../../shared/error-push/ErrorPushService";
import {EasyApi} from "../../../../services/EasyApi";
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-admin-addone-project-conterpartys',
  templateUrl: './admin-addone-project-conterpartys.component.html',
  styleUrls: ['./admin-addone-project-conterpartys.component.scss']
})
export class AdminAddoneProjectConterpartysComponent {

  public item: Project | null = null;
  IsLoading: boolean = false;

  @Input()
  set itemInput(value: Project | null) {
    if (value !== this.item) {
      this.item = value;
      this.Render();
    }
  }

  public list: Counterparty[] = [];

  constructor(
    private easyValidatorForm: EasyValidatorForm,
    private errorPushService: ErrorPushService,
    private cdr: ChangeDetectorRef,
    private apiService: EasyApi,
    private router: Router
  ) {


  }


  readonly statusOptions = [
    PlatformProjectContractStatus.none,
    PlatformProjectContractStatus.review,
    PlatformProjectContractStatus.error,
    PlatformProjectContractStatus.accepted,
  ];


  async Render() {
    if (!this.item) return;


    this.IsLoading = true;

    var s = this.testForm.value["searchInput"] ?? "";
    s =  s.trim();


    this.list = [];
    var response = await this.apiService.Get<Counterparty[]>(`/api/smz-service/admin/company/` + (this.item?.id) + `/counterpartys?s=`+s);


    console.log(response);
    if (!response.isSuccess || response.value == null) {
      console.log("Не принят");
      this.IsLoading = false;
      return;
    }

    this.list = response.value;

    this.IsLoading = false;
    this.cdr.detectChanges();

  }

  testForm = new FormGroup({
    searchInput: new FormControl(''),
  });

  async Submit( ) {

     this.Render();
  }
  OnClick(item: Counterparty) {

  }
}

