<div class="tui-container tui-skeleton_center" style="max-width: 390px; padding-top: 8%;">

  <tui-island>

    <ng-content></ng-content>
  </tui-island>

</div>


