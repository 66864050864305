import { Pipe, PipeTransform } from "@angular/core";
import {PlatformProjectContractStatus} from "../contracts/ContractsAdmin";
import {ForProjectConvertors} from "../BuisnesLayer/ProjectEnumConverter";

@Pipe({
  name: "formatExample",
  standalone: true
})
export class FormatPipeExample implements PipeTransform {
  transform(value?: string|null ): string {

    return  "XXXX";
  }
}


@Pipe({
  name: "PlatformStatusPipe",
  standalone: true
})
export class PlatformStatusPipe implements PipeTransform {
  transform(value?: PlatformProjectContractStatus ): string {

    if (value==null)return  "Статус не указан";
    return  ForProjectConvertors.TranslatePlatformProjectContractStatus(value)  ;
  }
}
