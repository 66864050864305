

<app-form-modal-base [open]="open" [isLoading]="isLoading" [title]="title">


  <button tuiButton (click)="title='New title'">Set tittle</button>
  <button tuiButton (click)="isLoading=true"> Loading</button>
  <button tuiButton (click)="open=false"> Close</button>


</app-form-modal-base>

<button tuiButton (click)="open=true"> Open base modal</button>
