import {Component} from '@angular/core';
import {SignalrService} from "../../services/signalr.service";
import {EasyApi} from "../../services/EasyApi";

@Component({
  selector: 'app-signal-tutorial',
  templateUrl: './signal-tutorial.component.html',
  styleUrls: ['./signal-tutorial.component.scss']
})
export class SignalTutorialComponent {


  async SendSignal() {
    console.log("SendSignal");
    let res = await this.api.Post<any>('/api/Signal/SignalCreateTest', {name: "Gavno"});
    console.log(res);
  }

  constructor(
    private api: EasyApi,
    private signalrService: SignalrService
  ) {


   // console.log("[SINGAL] construct");

    if (!this.signalrService.hubConnection){
      console.log("[SINGAL] not hubConnection");
      return;
    }


    this.signalrService.hubConnection.on('Example', (data:any) => {
      console.log("++++[SINGAL] INNER");
      console.log(data);
    });
  }


}
