import { Component } from '@angular/core';

@Component({
  selector: 'app-carusel-index-info',
  templateUrl: './carusel-index-info.component.html',
  styleUrls: ['./carusel-index-info.component.scss']
})
export class CaruselIndexInfoComponent {

}
