import {ChangeDetectorRef, Component, Inject, Injectable, ViewChild} from '@angular/core';
import {EasyApi} from "../../../services/EasyApi";
import {ChatControllerComponent} from "../chat-controller/chat-controller.component";
import {Message} from "../ChatModels";
import {User} from "../../../shared/interfaces";
import {map, take} from "rxjs";
import {TuiPushService} from "@taiga-ui/kit";
import {SignalrService} from "../../../services/signalr.service";
import {HistoryChatComponent} from "../history-chat/history-chat.component";
import {Router} from "@angular/router";
import {ChatLinker} from "../ChatLinker";

@Component({
  selector: 'app-signal-notify-chat',
  templateUrl: './signal-notify-chat.component.html',
  styleUrls: ['./signal-notify-chat.component.scss']
})
export class SignalNotifyChatComponent {



  constructor(
    private chatLinker: ChatLinker,
    private router: Router,
    private signalrService: SignalrService,
    @Inject(TuiPushService) protected readonly push: TuiPushService,
    private api: EasyApi,
    private  cdr: ChangeDetectorRef
  ) {

    this.SingalConnect();
  }




  SingalConnect() {

    if (!this.chatLinker.historyChatComponent) {
      console.log("[not historyChatComponent");
    }

    if (!this.signalrService.hubConnection) {
      console.log("[SINGAL] not hubConnection");
      return;
    }

    this.signalrService.hubConnection.on('MessageNew', (data: Message) => {


      let curId = "";
      if (this.chatLinker.historyChatComponent) {
        console.log(this.chatLinker.historyChatComponent.targetChat);
        if (this.chatLinker.historyChatComponent.targetChat) {
          console.log(this.chatLinker.historyChatComponent.targetChat);
          if (this.chatLinker.historyChatComponent.targetChat.ReceiverUserId) {
            curId = this.chatLinker.historyChatComponent.targetChat.ReceiverUserId;
          }
        }
      }
      console.log(curId);

      if (data.userId == curId) {

        if (this.chatLinker.historyChatComponent) {
          this.chatLinker.historyChatComponent.AddMessage(data);
        }

      } else {


        var fakeUser: User = {
          balance: 0, email: "", id: data.userId, username: data.name
        };

        this.push
          .open(data.text.substring(0, 40), {
            heading: data.name,
            type: 'Сообщение',
            icon: 'tuiIconSend',
            buttons: ['Открыть'],
          })
          .pipe(
            take(1),
            map(button => {
              this.router.navigateByUrl(`/chat/${data.userId}`);
            }),
          )
          .subscribe();

      }
    });
  }

}
