<div class="col p-4 border-bottom border-1">
  <img src="/assets/img/logo_f_b.png" height="39" loading="lazy">


  <tui-badge
    size="m"
    value="BETA"
    class="float-end mt-1"
    [hoverable]="true"
  ></tui-badge>
</div>


<div class="col p-4 border-bottom border-1">

  <app-user-profile-button></app-user-profile-button>
</div>


<div class="col p-4 border-bottom border-1">

  <div class="mb-1">
    Рендер спектаклей:

    <tui-tooltip
      style="margin-top: -3px;"
      content="Время доступное для рендеринга на вашем тарифе"
      direction="right"
    ></tui-tooltip>

    <small class="float-end">
      2:52
    </small>
  </div>

  <progress
    max="100"
    new
    size="xs"
    tuiProgressBar
    [value]="55"
  ></progress>



  <div class="mb-1">
    Символы:

    <tui-tooltip
      style="margin-top: -3px;"
      content="Время доступное для рендеринга на вашем тарифе"
      direction="right"
    ></tui-tooltip>

    <small class="float-end">
      25 k
    </small>
  </div>

  <progress
    max="100"
    new
    size="xs"
    tuiProgressBar
    [value]="85"
  ></progress>



  <a
    tuiButton
    size="s"
    type="button"
    icon="true"
    iconRight="true"

    appearance="outline"
    class="tui-space_top-4 w-100"
    href="/plans"
  >
    Купить тариф
  </a>
</div>



<div class="col p-4 pt-1 border-bottom border-1">
  <h3 class="mb-2">Мои приложения</h3>
  <a href tuiLink class=" text-decoration-none">Основные</a>
  <BR>
  <a href class=" text-decoration-none">Основные</a>
  <BR>
  <a href class=" text-decoration-none">Основные</a>
  <BR>
</div>
