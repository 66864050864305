import {Component, OnInit} from '@angular/core';
import {SignalrService} from "../../../services/signalr.service";
import {EasyApi} from "../../../services/EasyApi";

@Component({
  selector: 'app-signal-stater',
  templateUrl: './signal-stater.component.html',
  styleUrls: ['./signal-stater.component.scss']
})
export class SignalStaterComponent  implements OnInit{
  constructor(public signalRService: SignalrService, private api: EasyApi) {
  }

  ngOnInit() {
    this.signalRService.startConnection();
  }


}
