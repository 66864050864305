import {ChangeDetectorRef, Component} from '@angular/core';
import {EasyApi} from "../../services/EasyApi";
import {User} from "../../shared/interfaces";
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-users-admin',
  templateUrl: './users-admin.component.html',
  styleUrls: ['./users-admin.component.scss']
})
export class UsersAdminComponent {

  constructor(
    private router: Router,
    private apiService: EasyApi,
    private cdr: ChangeDetectorRef) {
    this.MyList();
  }
  public currentList: User[] = [];



  async Search(s: string) {

    var response = await this.apiService.Get<User[]>(`/api/AdminAccount/Search?s=${s}`);

    this.currentList = [];
    if (response.value) {
      this.currentList = response.value;

    }
    this.cdr.detectChanges();
  }

  OnClick(item: User) {
    console.log(item.username);
    console.log(item);

    this.router.navigateByUrl(`/user/${item.id}`);
  }

  async MyList() {

    var response = await this.apiService.Get<User[]>(`/api/AdminAccount/Search`);

    this.currentList = [];
    if (response.value) {
      this.currentList = response.value;
    }
    this.cdr.detectChanges();
  }

  testForm = new FormGroup({
    searchInput: new FormControl('', Validators.required),
  });

  async Submit( ) {

    var s = this.testForm.value["searchInput"];

    if (s) {
      this.Search(s);
    }else {
      this.MyList();
    }
  }
}
