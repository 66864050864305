import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-addone-user-ban',
  templateUrl: './admin-addone-user-ban.component.html',
  styleUrls: ['./admin-addone-user-ban.component.scss']
})
export class AdminAddoneUserBanComponent {

}
