import {User} from "../../shared/interfaces";
import {MemberTypeEnum} from "./CustomContractsAdmin";

export interface Reestr   {
  id: string;
  name: string;
  projectId: string;
  payAmount: number;
  taxAmount: number;
  fileLinkId?: string;
  createdAt: Date;
  updatedAt?: Date;
  excitedAt?: Date;
  //transactions?: Transaction[];
  status: ReestrStatus;
}

export enum ReestrStatus {
  Draft,
  Created,
  Planned,
  InProgress,
  Executed,
  Rejected
}

export interface Counterparty {
  id: string;
  projectId: string;
  inn: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  phoneNumber: string;
  bank: string;
  cardNumber: string;
  osnovanie: string;
  isTaxWithhold: boolean;
  isEdoAgreement: boolean;
}

export interface Project {
  id: string,
  ownerId?: string,
  teamId?: string,
  name: string,
  createdAt: Date,
  inn?: string | null,
  kpp?: string | null,
  address?: string | null,
  addressCors?: string | null,
  nameDirector?: string | null,
  typeDirector?: string | null,
  osnovanie?: string | null,
  nameContact?: string | null,
  phoneContact?: string | null,
  balance?: number,
  planId?: string | null,
  platformContractStatus?: PlatformProjectContractStatus,
  platformContractFileId?: string | null,
}

export enum PlatformProjectContractStatus {
  none ,
  review ,
  error ,
  accepted ,
}

export interface TeamModel {
  id: string,
  ownerId?: string,
  accountId?: string,
  name: string,
  createdAt: Date,
  members: TeamMemberModel[],
}
export interface TeamMemberModel {
  id: string,
  teamId?: string,
  emailInvite?: string,
  memberType: MemberTypeEnum,
  accountId?: string,
  IsInvite: boolean,
  name: string,
  createdAt: Date,
  account?: User,
}

export interface FileLinkModel {
  id: string,
  name: string,
  userId?: string,
  urlFull: string,
  fileExt?: string,
  size: number,
  createdAt: Date,
}

export interface NotificationModel {
  id: string,
  accountId?: string,
  email?: string,
  isSuccesfull: boolean,
  title: string,
  content: string,
  createdAt: Date,
  type: NotificationTypeEnum,
}

export enum NotificationTypeEnum{
  Email = 0,
  Sms = 1,
}


export interface VerificationCodeModel {
  id: string,
  ownerId: string,
  ip?: string,
  isClosed: boolean,
  tryCount?: bigint,
  createdAt: Date,
  updatedAt?: Date,
}
