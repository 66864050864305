<app-admin-layout>



  <h3 class="tui-island__title tui-island_text-align_center">project</h3>



  <BR>
  <BR>

  <p>Нажимая «Далее», вы принимаете пользовательское соглашение и соглашаетесь на обработку вашей персональной
    информации на условиях политики конфиденциальности</p>

</app-admin-layout>
