
<tui-root  >

  <app-error-push/>
  <ng-container ngProjectAs="tuiOverContent">
    <router-outlet></router-outlet>
  </ng-container>


  <ng-container ngProjectAs="tuiOverDialogs">
    <!-- Content over dialogs -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverAlerts">
    <!-- Content over alerts -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverPortals">
    <!-- Content over dropdowns -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverHints">
    <!-- Content over hints -->
  </ng-container>


  <app-signal-stater></app-signal-stater>
</tui-root>
