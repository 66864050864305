import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {TeamMemberModel, TeamModel} from "../../contracts/ContractsAdmin";
import {EasyValidatorForm} from "../../../services/EasyValidatorForm";
import {ErrorPushService} from "../../../shared/error-push/ErrorPushService";
import {EasyApi} from "../../../services/EasyApi";
import {Router} from "@angular/router";
import {MemberTypeEnum, ProjectPermission, TeamNamingHelper} from "../../contracts/CustomContractsAdmin";

@Component({
  selector: 'app-teams-member-element-admin',
  templateUrl: './teams-member-element-admin.component.html',
  styleUrls: ['./teams-member-element-admin.component.scss']
})
export class TeamsMemberElementAdminComponent {

  public member?: TeamMemberModel | null = null;
  //public permMap?: Map<number, ProjectPermission[]> | null;
  public permMap?: { [key: number]: ProjectPermission[] } | null;
  public listHumanPerms: string = "x";

  selectedMemberType: MemberTypeEnum = MemberTypeEnum.Hr;

  @Input()
  set MemberInput(value: TeamMemberModel | null) {
    if (value !== this.member) {
      this.member = value;
      this.Render();
    }
  }

  public team?: TeamModel | null;

  constructor(
    private easyValidatorForm: EasyValidatorForm,
    private errorPushService: ErrorPushService,
    private cdr: ChangeDetectorRef,
    private apiService: EasyApi,
    private router: Router
  ) {


  }


  async Render() {
    this.ReadPermTypes();
    /*
    var response = await this.apiService.Get<TeamModel>(`/api/team-service/admin/team/` + (this.member));

    this.team = null;
    console.log("team:");
    console.log(response);

    if (response.value) {
      this.team = response.value;
    }
*/
    this.cdr.detectChanges();
  }

  async onMemberTypeSelected(selectedMemberType: MemberTypeEnum) {
    // Обработка выбранного типа


    console.log('Selected Member Type:', selectedMemberType);

    const data = {
      memberType: selectedMemberType
    };
    var resulst = await this.apiService.Post<boolean>(`/api/team-service/admin/MemberTypeSet/`+this.member?.id, data, true, true);
    // console.log(resulst);
    if (!resulst.isSuccess) {
      return;
    }

    this.GetHumanNames();
    this.cdr.detectChanges();

  }

  GetMemberTypeList() {
    let list = Object.values(MemberTypeEnum).filter(value => typeof value === 'number');
    return list;
  }

  GetHumanNames() {

    this.listHumanPerms = "Не определены права";
    if (this.permMap != null && this.member != null) {

      this.listHumanPerms = "";

      let xz = this.permMap[this.member.memberType];

      for (let i = 0; i < xz.length; i++) {
        this.listHumanPerms += TeamNamingHelper.PermisiionName(xz[i]);
        if (i + 1 < xz.length) this.listHumanPerms += ", ";
      }


    }
  }

  async ReadPermTypes() {

    if (this.permMap != null) return;

    var response = await this.apiService.Get<{ [key: number]: ProjectPermission[] }>(`/api/team-service/MembersPermissions/`);

    this.team = null;


    if (response.value != null) {
      this.permMap = response.value;
      this.GetHumanNames();
    }
    this.cdr.detectChanges();
  }

  GetCurrentType() {
    return 2;
    //if (!this.member)return  0;
    //return Number(this.member.memberType);
    //return 1;
  }
}
