<app-admin-layout>

  <tui-loader class="loader"
              [inheritColor]="true"
              [overlay]="true"
              [showLoader]="item==null">


    <div *ngIf="item==null" class="row">
      <div class="col-3">
        <tui-island class=" ">--- загрузка</tui-island>
      </div>
      <div class="col-3">
        <tui-island class=" ">---</tui-island>
      </div>
    </div>

    <div *ngIf="item!=null">

      <div class="row mt-2">

        <div class="col-3 p-0">

          <tui-island class="mb-2 ">
            <p class="tui-island__category">Компания</p>
            <h4 class="tui-island__title">{{item.name}}</h4>
            <p class="tui-island__paragraph">
              {{item.inn ?? "-"}}
            </p>
            <BR>

            {{ item.id }}


          </tui-island>





          <tui-island class="mb-2 mt-2">


            Дата создания:
            <BR> {{item.createdAt  | date }}

            <BR>
            <BR>
            Автор: <BR> <a href="/user/{{item.ownerId  }}">{{item.ownerId  }}</a>


          </tui-island>


          <tui-island class="mb-2 ">


            Статус платформы:
            <BR> {{item.platformContractStatus | PlatformStatusPipe }}  <BR> <BR>


            Адрес:
            <BR> {{item.address  }}  <BR> <BR>

            Коресп. Адрес:
            <BR> {{item.addressCors }} <BR> <BR>


            Директор:
            <BR> {{item.nameDirector }} <BR> <BR>

            Тип директора:
            <BR> {{item.typeDirector }} <BR> <BR>

            Основание:
            <BR> {{item.osnovanie }} <BR> <BR>

            Контакт:
            <BR> {{item.nameContact }} [ {{item.phoneContact }} ]<BR> <BR>


          </tui-island>
        </div>


        <div class="col-9 p-3">


          <tui-tabs class="mb-2">
            <ng-container *ngFor="let step of tabs; let last = last">
              <button
                tuiTab
                class="step"
                (click)="currentTab = step"
              >
                {{ step }}
              </button>

            </ng-container>
          </tui-tabs>


          <app-teams-weight-admin   *ngIf="currentTab=='Команда'" [teamidInput]="item.teamId ?? null"></app-teams-weight-admin>


          <app-admin-addone-project-platform-status *ngIf="currentTab=='Платформа'" [itemInput]="item"></app-admin-addone-project-platform-status>


          <app-admin-addone-project-conterpartys *ngIf="currentTab=='Самозанятые'" [itemInput]="item"></app-admin-addone-project-conterpartys>

          <app-admin-addone-project-reestrs *ngIf="currentTab=='Реестры'" [itemInput]="item"></app-admin-addone-project-reestrs>


        </div>
      </div>


    </div>


  </tui-loader>
</app-admin-layout>
