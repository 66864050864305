import {Injectable} from '@angular/core';
import {HubConnection, HubConnectionBuilder, HttpTransportType} from "@microsoft/signalr"
import {AuthService} from "./auth.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  public hubConnection?: HubConnection;

  constructor(
    private authService: AuthService
  ) {
  }


 public async  startConnection(){
    var t: string = "";// localStorage.getItem("access_token");

    if (localStorage.getItem("access_token")) {
      t = localStorage.getItem("access_token") ?? "BB";
    }
    //t = "XZBZXB";

    if (!t) return;

    if (!this.authService.isAuth()) {
     // console.log("Не подключен сигнал, нужна авторизация")
      return;
    }


    this.hubConnection = await new HubConnectionBuilder()
      .withUrl(environment.API_URL + '/signal', {
        accessTokenFactory: () => t,
      //headers: {"Authorization": "Bearer " + t}
      })
      .build();

    await this.hubConnection.start()
      .then(() => console.log('[SINGAL] Connection started'));
      //.catch(err => console.log('[SINGAL] Error while starting connection: ' + err))
  }




}
