<app-with-sidebar>

  <form [formGroup]="payForm" class="container">
    <h3 class="tui-form__header tui-form__header_margin-top_none">Пополнение баланса</h3>

    <div class="tui-form__row">
      <tui-input-slider
        formControlName="amount"
        tuiTextfieldPostfix="₽"
        [max]="3000"
        [min]="1"
        [segments]="1"
      >
        Сумма пополнения
      </tui-input-slider>


      <tui-error
        formControlName="amount"
        [error]="[] | tuiFieldError | async"
      ></tui-error>
    </div>

    <BR>
    <BR>
    <button tuiButton (click)="OnPayMake()">
      Пополнить
    </button>
  </form>


</app-with-sidebar>
