import {PlatformProjectContractStatus} from "../contracts/ContractsAdmin";

export class ForProjectConvertors {

  static TranslatePlatformProjectContractStatus(status: PlatformProjectContractStatus): string {
    switch (status) {
      case PlatformProjectContractStatus.none:
        return "Нет договора";
      case PlatformProjectContractStatus.review:
        return "На рассмотрении";
      case PlatformProjectContractStatus.error:
        return "Договор отклонен";
      case PlatformProjectContractStatus.accepted:
        return "Договор принят";
      default:
        return "-";
    }
  }

}

