
<div class="tui-container">




  <ng-content></ng-content>

</div>


