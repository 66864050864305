//export class BaseSearchAdminComponent<TypeItem> implements OnInit {


import {Router} from "@angular/router";
import {EasyApi} from "../../services/EasyApi";
import {ChangeDetectorRef} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BaseModelContract} from "./BaseContracts";

export class BaseSearchAdminComponent<TypeItem extends BaseModelContract> {


  constructor(
    protected router: Router,
    private apiService: EasyApi,
    private cdr: ChangeDetectorRef) {
    this.UpdateData();
  }

  public currentList: TypeItem[] = [];


  GenerateApiSearchEndpoint(s:string){
    return `/api/AdminAccount/Search?s=${s}`;
  }
  async Search(s: string) {

    var response = await this.apiService.Get<TypeItem[]>(this.GenerateApiSearchEndpoint(s));

    this.currentList = [];
    if (response.value) {
      this.currentList = response.value;

    }
    this.cdr.detectChanges();
  }

  OnClick(item: TypeItem) {
    this.router.navigateByUrl(`/user/${item.id}`);
  }

  async UpdateData() {

    var response = await this.apiService.Get<TypeItem[]>(this.GenerateApiSearchEndpoint(""));

    this.currentList = [];
    if (response.value) {
      this.currentList = response.value;
    }
    this.cdr.detectChanges();
  }

  testForm = new FormGroup({
    searchInput: new FormControl('', Validators.required),
  });

  async Submit() {

    var s = this.testForm.value["searchInput"];

    if (s) {
      this.Search(s);
    } else {
      this.UpdateData();
    }
  }
}
