<ng-container *ngFor="let item of contacts">
  <div
    (click)="ClickContact(item)"
    class="push  row tui-table__tr_cursor_pointer pointer chatContact "
  >

    <tui-avatar
      size="s"
      [autoColor]="true"
      [text]="item.username"
    ></tui-avatar>

    <span class="p-1 col">
      @{{item.username}}
    </span>


  </div>
</ng-container>
