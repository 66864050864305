

import {Pipe, PipeTransform} from '@angular/core';
import {MemberTypeEnum, ProjectPermission, TeamNamingHelper} from "../../contracts/CustomContractsAdmin";

@Pipe({name: 'PermissionName'})
export class PermissionNameTeamPipe implements PipeTransform {
  transform(value: ProjectPermission) {
    return TeamNamingHelper.PermisiionName(value);
  }
}

@Pipe({name: 'MemberTypeName'})
export class MemberTypeNameTeamPipe implements PipeTransform {
  transform(value: MemberTypeEnum) {
    return TeamNamingHelper.MemberTypeName(value);
  }

}


@Pipe({name: 'MemberTypeNameNumber'})
export class MemberTypeNameNumberTeamPipe implements PipeTransform {
  transform(value: string| MemberTypeEnum) {
    return TeamNamingHelper.MemberTypeName( (Number(value)));
  }

}

