<tui-island class=" " *ngIf="item!=null">

  <tui-loader class="loader"
              [inheritColor]="true"
              [overlay]="true"
              [showLoader]="IsLoading">

    <h3>
      Самозанятые <b style="opacity: 0.5;">( {{list.length}} )</b>
    </h3>


    <form [formGroup]="testForm" (ngSubmit)="Submit()" class="mb-2">
      <tui-input
        formControlName="searchInput"
        [tuiTextfieldLabelOutside]="true"
        tuiTextfieldPostfix="      Нажмите Enter"
        [tuiTextfieldSize]="'m'"

        [tuiTextfieldIconLeft]="'tuiIconSearch'"
      >
        Введите Ф.И.О, ИНН или Номер телефона
      </tui-input>
      <tui-error
        formControlName="searchInput"
        [error]="[] | tuiFieldError | async"
      ></tui-error>
    </form>

    <div class="row p-3" style=" font-weight: bold; opacity: 0.6;">
      <div class="col-2">
        Ф.И.О.
      </div>
      <div class="col">
        Контакт
      </div>
      <div class="col">
        Номер карты
      </div>
      <div class="col">
        Удержание налога
      </div>
      <div class="col">
        ЭДО
      </div>
      <div class="col">

      </div>
    </div>


    <div *ngFor="let element of list">

      <tui-island class="mb-1 tui-table__tr_cursor_pointer  ">

        <div class="row " style="padding-left: 12px; font-size: 14px;">


          <div class="col-2 p-0">
            {{element.firstName}}   {{element.patronymic}}   {{element.lastName}}
            <BR> <small>   {{element.inn  }}</small>
          </div>

          <div class="col">
            {{ element.phoneNumber  | tuiFormatPhone }}
          </div>


          <div class="col">
            {{ element.cardNumber  | tuiFormatCard }}
          </div>


          <div class="col">
            +
          </div>
          <div class="col">
            +
          </div>

          <div class="col  ">
            <a (click)="OnClick(element)" tuiLink>Перейти</a>
          </div>
        </div>
      </tui-island>

    </div>

  </tui-loader>
</tui-island>
