<ng-container *ngIf="contact!=null || true">

  <div class="chatBoxFormComponent  ">
  <div class="chatBoxFormComponentInner  ">
    <div class="row  ">
      <div class="col">
        <tui-textarea
          style="min-height: 0px;"
          [formControl]="textarea"
          class="  field-small chatBoxFormComponentTextArea"
          [expandable]="true"
          [maxLength]="2230"
          (keydown.control.enter)="SendMessage()"
        >Сообщение
        </tui-textarea>

      </div>
      <div class="col-auto">

        <button
          icon="tuiIconSend"
          tuiIconButton
          appearance="icon"
          size="s"
          type="button"
          (click)="SendMessage()"
          class="tui-space_right-4 rounded-circle mb-2"
        ></button>
        <BR>
        <button
          icon="tuiIconMic"
          tuiIconButton
          appearance="icon"
          size="s"
          type="button"
          (click)="SendMessage()"
          class="tui-space_right-4 rounded-circle visually-hidden "
        ></button>

      </div>
    </div>
  </div>
  </div>
</ng-container>
