<ng-template #header>
  <div
    class="header"
    [style.backgroundImage]="background"
  ></div>
</ng-template>

<ng-template
  [tuiDialogOptions]="{size: 's', header: header}"
  [(tuiDialog)]="open"
>
  <div
    tuiCarouselButtons
    class="wrapper"
    (touchstart.passive.stop)="(0)"
  >
    <button
      icon="tuiIconChevronLeftLarge"
      tuiIconButton
      tuiMode="onDark"
      type="button"
      class="tui-space_right-4"
      (click)="navigate(-1)"
    ></button>

    <tui-carousel [(index)]="index">
      <ng-container  *ngFor="let item of listTutorial">
      <div *tuiItem>
        <h2>{{item.title}}</h2>
        <p>{{item.text}}</p>
      </div>
      </ng-container>
    </tui-carousel>

    <button
      icon="tuiIconChevronRightLarge"
      tuiIconButton
      tuiMode="onDark"
      type="button"
      class="tui-space_left-4"
      (click)="navigate(1)"
    ></button>
  </div>
  <tui-pagination
    size="s"
    class="tui-space_top-4"
    [length]="3"
    [(index)]="index"
  ></tui-pagination>
  <div class="tui-space_top-4">
    <button
      appearance="primary"
      tuiButton
      type="button"
      (click)="navigate(1)"
    >
      Дальше
    </button>
  </div>
</ng-template>
