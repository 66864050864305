<tui-scrollbar class="box " style="height: 100%;  ">
  <div class="content">

    <span *ngIf="messages.length==0">Пока что нет сообщений!</span>

    <ng-container *ngFor="let msg of messages">

      <div class="  messageBlock">
        <tui-push
          [type]="msg.name"
          class="push mb-3  messageItem"

          [class.float-endx]=" contactAccount!=null "

          [timestamp]="GetTimeCreated(msg)"
        >
          <tui-svg src="tuiIconUser"></tui-svg>
          <small>{{msg.text}}</small>
        </tui-push>
      </div>

    </ng-container>
  </div>
</tui-scrollbar>

