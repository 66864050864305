
  <tui-island class=" " *ngIf="item!=null">

    <p>
      Попытки авторизации
    </p>

    <div *ngIf=" list.length==0">
      Не найдено попыток
    </div>

    <div *ngFor="let inner of list">

      <tui-island class="mb-1 tui-table__tr_cursor_pointer  ">
        {{inner.isClosed ? 'Успешная авторизация' : 'Не пройдено'}}

        <BR>
        <small>{{inner.ip}}</small> -
        <small>  {{inner.createdAt | date}}</small>


        <div class="row " style="padding-left: 12px; font-size: 14px;">
        </div>
      </tui-island>
    </div>

  </tui-island>
