<tui-block-status>
  <img
    alt="not found"
    src="./assets/images/not-found.svg"
    tuiSlot="top"
    class="image"
  />

  <h4>Not found</h4>

  Try to find by number
</tui-block-status>
