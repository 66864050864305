import {AuthService} from "./auth.service";
import { Injectable} from "@angular/core";
import {ErrorPushService} from "../shared/error-push/ErrorPushService";
import {AbstractControl, FormGroup, Validators} from "@angular/forms";


@Injectable({
  providedIn: 'root'
})

export class EasyValidatorForm {
  constructor(
    private errorService: ErrorPushService,
    private authService: AuthService,
  ) {
  }

  passwordValidator(field: AbstractControl): Validators | null {
    var latinChars = /^[a-zA-Z]+$/;
    return field.value && latinChars.test(field.value)
      ? null
      : {
        other: 'Only latin letters are allowed',
      };
  }

  loginValidator(field: AbstractControl): Validators | null {
    var latinChars = /^[0-9a-zA-Z_]+$/;

    //if (field.value.trim("_") != field.value) return {other: 'Черточки только внутри должны быть'};

    return field.value && latinChars.test(field.value)
      ? null
      : {
        other: 'Только английские буквы',
      };
  }

  nameCleanValidator(field: AbstractControl): Validators | null {
    var allChars = /^[0-9a-zA-Zа-яА-Я_ ]+$/;

    //if (field.value.trim("_") != field.value) return {other: 'Черточки только внутри должны быть'};

    return field.value && allChars.test(field.value)
      ? null
      : {
        other: 'Не корректные символы',
      };
  }

  FormFromKeyAndError(group: FormGroup, keyName: string, errorMessage: string, otherVariantNameFiled: string) {
    if (errorMessage.toLowerCase().indexOf(keyName.toLowerCase()) > -1 || errorMessage.toLowerCase().indexOf(otherVariantNameFiled.toLowerCase()) > -1) {
      group.controls[keyName].setErrors({customError: errorMessage});
      group.controls[keyName].markAsTouched();
    }
  }
}
