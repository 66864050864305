import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from "../../services/auth.service";
import {ResponseFormat} from "../../shared/interfaces";
import {AuthTokentResponse} from "../../engine/models/AuthTokentResponse";
import {Router} from "@angular/router";
import {EasyApi} from "../../services/EasyApi";
import {ErrorPushService} from "../../shared/error-push/ErrorPushService";
import {EasyValidatorForm} from "../../services/EasyValidatorForm";
import {AuthStateEnum} from "./auth-contracts";

@Component({
  selector: 'app-auth-form',
  templateUrl: './auth-form.component.html',
  styleUrls: ['./auth-form.component.scss']
})


export class AuthFormComponent implements OnInit {

  cdr: ChangeDetectorRef;
  AuthStateEnum = AuthStateEnum;
  private smsVerificationId: string | undefined;
  smsVerificationPhoneNumber: string | undefined;

  constructor(
    private easyValidatorForm: EasyValidatorForm,
    private errorPushService: ErrorPushService,
    private api: EasyApi,
    authService: AuthService,
    cdr: ChangeDetectorRef, private router: Router) {

    this.authService = authService;
    this.cdr = cdr;

    if (authService.isAuth()) {
      this.isSending = true;
      this.errorMessage = "Вы авторизированы!";
    }
  }


  passwordValidator(field: AbstractControl): Validators | null {
    var latinChars = /^[a-zA-Z]+$/;
    return field.value && latinChars.test(field.value)
      ? null
      : {
        other: 'Only latin letters are allowed',
      };
  }

  loginValidator(field: AbstractControl): Validators | null {
    var latinChars = /^[a-zA-Z_]+$/;

    //if (field.value.trim("_") != field.value) return {other: 'Черточки только внутри должны быть'};

    return field.value && latinChars.test(field.value)
      ? null
      : {
        other: 'Только английские буквы',
      };
  }

  authService: AuthService;
  group: FormGroup = new FormGroup({
    phone: new FormControl('', [Validators.required]),
    mail: new FormControl('admin@mail.ru', [Validators.required, Validators.email]),
    password: new FormControl('1111', [Validators.required, this.passwordValidator]),
    login: new FormControl('1111', [Validators.required, this.loginValidator]),
    pin: new FormControl('1111', [Validators.required]),
  });

  isLoginWait: boolean = true;
  isSending: boolean = false;
  errorMessage: string = "";

  isSusessfule: boolean = false;

  title = 'Вход';

  ngOnInit() {
    //  this.group
    // this.onChanges();
  }

  ErrorHanding(e: any): void {

    this.isSending = false;
    if (!e.errorMessage) {
      this.errorMessage = "Ошибка сервера";
      this.cdr.detectChanges();
      return;
    }
    this.errorMessage = e.errorMessage;
    this.cdr.detectChanges();
    this.isLoginWait = false;
  }

 // isRegistration: boolean = false;
  stateForm: AuthStateEnum = AuthStateEnum.Login;

  ClickGoogle(): void {
    if (this.isSending) return;
    this.errorPushService.showError("Сервис пока что не доступен");
  }

  TogleMode(): void {
    if (this.isSending) return;

    if (this.stateForm ==AuthStateEnum.Registration){
      this.stateForm = AuthStateEnum.Login;
    }else {
      this.stateForm = AuthStateEnum.Registration;
    }
  }

  async ClickSendPin() {

    if (this.isSending) return;


    var data = {
      id: this.smsVerificationId,
      code: this.group.value['pin'],
      adminPermission: true,
    }
    this.isSending = true;
    var resulst = await this.api.Post<AuthTokentResponse>("/api/Auth/Verification", data, true, true);
   // console.log(resulst);

    this.isSending = false;
    this.cdr.detectChanges();

    if (!resulst.isSuccess || !resulst.value) {
      this.easyValidatorForm.FormFromKeyAndError(this.group, "pin", resulst.errorMessage, "pin");
      return;
    }


    this.isSending = true;
    this.authService.saveToken(resulst.value.access_token);
    this.isSusessfule = true;
    this.cdr.detectChanges();

    setTimeout(function () {
      location.href = "/users";
    }, 400);

  }

  async ClickSendReg() {
    if (this.isSending) return;


    var data = {
      email: this.group.value['mail'],
      phone: this.group.value['phone'],
      password: this.group.value['password'],
      username: this.group.value['login'],
      pin: this.group.value['pin'],
    }
    this.isSending = true;
    var resulst = await this.api.Post<AuthTokentResponse>("/api/Auth/Register", data, true, true);


    this.isSending = false;
    this.cdr.detectChanges();

    if (!resulst.isSuccess) {
      this.easyValidatorForm.FormFromKeyAndError(this.group, "login", resulst.errorMessage, "логин");
      this.easyValidatorForm.FormFromKeyAndError(this.group, "mail", resulst.errorMessage, "email");
      this.easyValidatorForm.FormFromKeyAndError(this.group, "phone", resulst.errorMessage, "phone");
      this.easyValidatorForm.FormFromKeyAndError(this.group, "password", resulst.errorMessage, "пароль");
      return;
    }

    this.smsVerificationId =  resulst.value?.smsVerificationId;
    this.smsVerificationPhoneNumber =  resulst.value?.smsVerificationPhoneNumber;

      this.stateForm = AuthStateEnum.SmsForm;
    localStorage.removeItem("isFirstTime");
  }

  ClickSendAuth(): void {
    if (this.isSending) return;
    var login: string = this.group.value['phone'];
    var pin: string = this.group.value['password'];
    this.isSending = true;
    this.errorMessage = "";
    this.isLoginWait = true;

    this.authService.login(login, pin).subscribe({
      next: (data: ResponseFormat<AuthTokentResponse>) => {
        this.isSending=false;

        if (data.value) {
          this.authService.saveToken(data.value.access_token);


          this.isSusessfule = true;

          this.smsVerificationId = data.value.smsVerificationId;
          this.smsVerificationPhoneNumber = data.value.smsVerificationPhoneNumber;
          this.stateForm = AuthStateEnum.SmsForm;
        }
        this.cdr.detectChanges();

        localStorage.removeItem("isFirstTime");
      },
      error: (e: any) => {
        this.ErrorHanding(e.error);
      }
    });
  }


}
