import {ChangeDetectorRef, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ResponseFormat} from "../../../shared/interfaces";
import {AuthService} from "../../../services/auth.service";
import {interval, take, takeWhile} from "rxjs";
import {PayCheckResponse} from "../PaymenModels";

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent {


  balance: number = 0;

  orderId: any;

  constructor(private route: ActivatedRoute, private _authService: AuthService, private cdr: ChangeDetectorRef) {
  }

  isGUID(value: string): boolean {
    const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return regex.test(value);
  }

  isLoading: boolean = true;
  isTarifMode: boolean = false;

  CheckPay(orderId: any) {

    if (!this.isGUID(orderId)) {
      console.log("Ошибка ИД");
      return;
    }

    const formData = new FormData();
    formData.append("id", orderId);

    this._authService.post("/api/Payment/CheckStatus", formData).subscribe({
      next: (data: ResponseFormat<PayCheckResponse>) => {
        console.log(data);

        this.isLoading = false;
        if (data.value?.planId) {
          if (data.value.planId != null) {
            this.isTarifMode = true;
          }
        }

        this._authService.getCurrentUserUpdate();
        this.balance = this._authService.getCurrentUser()?.balance ?? 0;
        this.cdr.detectChanges();

      },
      error: (e: any) => {
        console.log(e);
        console.log(e.error);
        // this.ErrorHanding(e.error);
      }
    });

  }

  ngOnInit() {

    this.balance = this._authService.getCurrentUser()?.balance ?? 0;
    this.balance -= 100;

    this.route.queryParams.subscribe(params => {
      this.orderId = params['order_id'];
//      console.log(this.orderId);
      this.CheckPay(this.orderId);


    });
  }
}
