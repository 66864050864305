<app-admin-layout>


  <tui-island
    size="l"


    style="background:#eaeaea; background-size: cover; padding: 15px 50px; color: #48AC98;"
    textAlign="center"
  >


    <h3 class="title">Поиск по компаниям</h3>

    <form [formGroup]="testForm" (ngSubmit)="Submit()">
      <tui-input
        formControlName="searchInput"
        class="input"
        [tuiTextfieldLabelOutside]="true"
        tuiTextfieldPostfix="      Нажмите Enter"
        [tuiTextfieldSize]="'l'"
        [tuiTextfieldIcon]="'tuiIconSearch'"
      >
        Название, инн ..
      </tui-input>
      <tui-error
        formControlName="searchInput"
        [error]="[] | tuiFieldError | async"
      ></tui-error>
    </form>
  </tui-island>

  <tui-loader class="loader"
              [inheritColor]="true"
              [overlay]="true"
              [showLoader]="currentList.length==0">


    <div class="row p-3" style=" font-weight: bold; opacity: 0.6;">
      <div class="col-2">
        Название
      </div>
      <div class="col">
        ИНН
      </div>
      <div class="col">
        Контакт
      </div>
      <div class="col">
        Баланс
      </div>
      <div class="col">
        Договор с платформой
      </div>
      <div class="col">
        Дата создания
      </div>
      <div class="col">
        Быстрый доступ
      </div>
    </div>


    <div *ngFor="let item of currentList" (click)="OnClick(item)">

      <tui-island class="mb-1 tui-table__tr_cursor_pointer  ">

        <div class="row " style="padding-left: 12px; font-size: 14px;">


          <div class="col-2">
            {{(item.name ?? "без названия").replace("ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ","ООО")}}
          </div>

          <div class="col">
            {{item.inn ?? "-"}}
          </div>

          <div class="col">
            {{ item.phoneContact ?? "" | tuiFormatPhone }}
          </div>

          <div class="col">
            {{item.balance ?? "-"}} {{ 'RUB' | tuiCurrency }}
          </div>
          <div class="col">
            {{item.platformContractStatus | PlatformStatusPipe }}
          </div>
          <div class="col">
            {{item.createdAt  | date }}
          </div>

          <div class="col">
            Быстрый доступ
          </div>
        </div>
      </tui-island>

    </div>


  </tui-loader>
</app-admin-layout>
