import {ChangeDetectorRef, Component} from '@angular/core';
import {Router} from "@angular/router";
import {EasyApi} from "../../services/EasyApi";
import {User} from "../../shared/interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Project} from "../contracts/ContractsAdmin";

@Component({
  selector: 'app-projects-admin',
  templateUrl: './projects-admin.component.html',
  styleUrls: ['./projects-admin.component.scss']
})
export class ProjectsAdminComponent {

  constructor(
    private router: Router,
    private apiService: EasyApi,
    private cdr: ChangeDetectorRef) {
    this.MyList();
  }
  public currentList: Project[] = [];



  async Search(s: string) {

    var response = await this.apiService.Get<Project[]>(`/api/admin/AdminProject?s=${s}`);

    this.currentList = [];
    if (response.value) {
      this.currentList = response.value;

    }
    this.cdr.detectChanges();
  }

  OnClick(item: Project) {
    console.log(item.name);
    console.log(item);

    this.router.navigateByUrl(`/projects/${item.id}`);
  }

  async MyList() {

    var response = await this.apiService.Get<Project[]>(`/api/admin/AdminProject`);

    this.currentList = [];
    if (response.value) {
      this.currentList = response.value;
    }
    this.cdr.detectChanges();
  }

  testForm = new FormGroup({
    searchInput: new FormControl('', Validators.required),
  });

  async Submit( ) {

    var s = this.testForm.value["searchInput"];

    if (s) {
      this.Search(s);
    }else {
      this.MyList();
    }
  }
}
