<app-container-layout>


  <app-example-form-modal-base/>


  <div class="tui-row  ">
    <div class="tui-col tui-col_md-6 tui-padding-4 ">
      xas saf
    </div>
    <div class="tui-col">
      x
    </div>
    <div class="tui-col">
      x
    </div>
  </div>



  <section>
    <h2>Install Taiga UI</h2>
    <p class="tui-space_top-2">You can easily install Taiga UI by running the following command:</p>

  </section>

  <div class="tui-row">
    <div class="tui-col_md-4">
      <tui-island>
        <p class="tui-island__category">category</p>
        <h3 class="tui-island__title">Some heading</h3>
        <p class="tui-island__paragraph">
         Не найдено дополнительных данных о пользователи...
        </p>
        <p class="tui-island__paragraph tui-island__paragraph_link">
          <a
            href="/"
            tuiLink
          >
            Link
          </a>
        </p>
      </tui-island>
    </div>
    <div class="tui-col_md-4">
      <tui-island>
        <h3 class="tui-island__title">Title</h3>
        <p class="tui-island__paragraph">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
          et dolore magna aliqua.
        </p>
        <button
          appearance="flat"
          size="m"
          tuiButton
          type="button"
          class="tui-island__footer-button"
        >
          Button
        </button>
      </tui-island>
    </div>
    <div class="tui-col_md-4">
      <tui-island>
        <div class="tui-island__content">
          <figure class="tui-island__figure">
            <div class="some-figure"></div>
          </figure>
          <div>
            <p class="tui-island__paragraph">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua.
            </p>
            <p class="tui-island__paragraph tui-island__paragraph_link">
              <a
                href="/"
                tuiLink
              >
                Fill some papers
              </a>
            </p>
          </div>
        </div>
      </tui-island>
    </div>
  </div>


  <tui-root>


    <p>Here you can reproduce any Taiga UI example</p>
    <ng-template #tooltip>Allowed symbols: ♠ ♣ ♦ ♥</ng-template>

    <tui-money
      [value]="10728.9"
    ></tui-money>


    <form
      class="b-form"
      [formGroup]="group"
    >
      <tui-input formControlName="name">Name</tui-input>
      <tui-select
        class="tui-space_vertical-3"
        [ngModelOptions]="{standalone: true}"
        [(ngModel)]="type"
      >
        Connection
        <tui-data-list-wrapper
          *tuiDataList
          [items]="items"
        ></tui-data-list-wrapper>
      </tui-select>
      <tui-input
        *ngIf="type === items[0]; else phone"
        formControlName="contact"
        [tuiValidator]="validator"
      >
        Contact
      </tui-input>
      <ng-template #phone>
        <tui-input-phone formControlName="contact">Contact</tui-input-phone>
      </ng-template>
    </form>


  </tui-root>
</app-container-layout>
