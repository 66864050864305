
<div class="plan " *ngIf="plan!=null" style="background: {{colorList[plan.level]}};">

  <h1>{{plan.name}}</h1>
  <span class="price">{{plan.price}} ₽</span>
  <span class="costFor">В месяц</span>


  <ng-container   *ngIf="plansPage.currentPlanId == plan.id  ">
    <h3 style="margin-top: 29px; margin-bottom: 35px;">Текущий тариф</h3>
  </ng-container>

  <button
    *ngIf="plansPage.currentPlanId != plan.id  &&  plan.id!='free'"
    tuiButton
          (click)="Click()"
          size="m" appearance="secondary">Купить</button>

  <div class="details">


    <ng-container *ngFor="let prop of plan.compare | keyvalue">

      <div class="detail">

        <svg *ngIf="IsNotEmptyDetail(prop.value)" height="17" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.72702C12 6.3188 11.04 6.76077 10.86 7.29262C10.68 7.82448 11.19 8.79081 10.86 9.23277C10.53 9.67474 9.48 9.49495 9.015 9.83205C8.55 10.1691 8.4225 11.2104 7.86 11.3976C7.2975 11.5849 6.6075 10.7984 6.0075 10.7984C5.4075 10.7984 4.695 11.5475 4.155 11.3976C3.615 11.2478 3.465 10.1691 3 9.83205C2.535 9.49495 1.5 9.69721 1.155 9.23277C0.81 8.76833 1.3275 7.85444 1.155 7.29262C0.9825 6.7308 0 6.3188 0 5.72702C0 5.13524 0.96 4.69327 1.14 4.16142C1.32 3.62956 0.81 2.66323 1.14 2.22127C1.47 1.77931 2.5275 1.95909 3 1.622C3.4725 1.28491 3.585 0.243668 4.1475 0.0339218C4.71 -0.175824 5.4 0.655668 6 0.655668C6.6 0.655668 7.3125 -0.0934239 7.8525 0.0563945C8.3925 0.206213 8.535 1.28491 9 1.622C9.465 1.95909 10.5 1.75683 10.845 2.22127C11.19 2.68571 10.6725 3.5996 10.845 4.16142C11.0175 4.72324 12 5.13524 12 5.72702Z" fill="#3BA55C"/>
          <path d="M5.66357 8.47055L3.13232 6.55632L3.87681 5.5354L5.36578 6.68394L8.07571 3.0882L9.08821 3.83887L5.66357 8.47055Z" fill="white"/>
        </svg>

        <svg *ngIf="!IsNotEmptyDetail(prop.value)" height="17" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.72702C12 6.3188 11.04 6.76077 10.86 7.29262C10.68 7.82448 11.19 8.79081 10.86 9.23277C10.53 9.67474 9.48 9.49495 9.015 9.83205C8.55 10.1691 8.4225 11.2104 7.86 11.3976C7.2975 11.5849 6.6075 10.7984 6.0075 10.7984C5.4075 10.7984 4.695 11.5475 4.155 11.3976C3.615 11.2478 3.465 10.1691 3 9.83205C2.535 9.49495 1.5 9.69721 1.155 9.23277C0.81 8.76833 1.3275 7.85444 1.155 7.29262C0.9825 6.7308 0 6.3188 0 5.72702C0 5.13524 0.96 4.69327 1.14 4.16142C1.32 3.62956 0.81 2.66323 1.14 2.22127C1.47 1.77931 2.5275 1.95909 3 1.622C3.4725 1.28491 3.585 0.243668 4.1475 0.0339218C4.71 -0.175824 5.4 0.655668 6 0.655668C6.6 0.655668 7.3125 -0.0934239 7.8525 0.0563945C8.3925 0.206213 8.535 1.28491 9 1.622C9.465 1.95909 10.5 1.75683 10.845 2.22127C11.19 2.68571 10.6725 3.5996 10.845 4.16142C11.0175 4.72324 12 5.13524 12 5.72702Z" fill="#4F545C"/>
        </svg>

        {{ prop.key }}: {{ prop.value }}
      </div>
    </ng-container>



  </div>
</div>
