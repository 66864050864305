import {ChangeDetectorRef, Component} from '@angular/core';
import {BaseSearchAdminComponent} from "../../base/BaseSearchAdminComponent";
import {User} from "../../../shared/interfaces";
import {Router} from "@angular/router";
import {EasyApi} from "../../../services/EasyApi";
import {TeamModel} from "../../contracts/ContractsAdmin";

@Component({
  selector: 'app-teams-search-admin',
  templateUrl: './teams-search-admin.component.html',
})
export class TeamsSearchAdminComponent  extends  BaseSearchAdminComponent<TeamModel> {

  constructor(router: Router, apiService: EasyApi, cdr: ChangeDetectorRef) {
    super(router, apiService, cdr);
  }

  override GenerateApiSearchEndpoint(s: string): string {
    return `/api/team-service/admin/teams/Search?s=${s}`;
  }

  override OnClick(item: TeamModel) {
    this.router.navigateByUrl(`/teams/${item.id}`);
  }

}
