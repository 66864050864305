import { Component} from '@angular/core';
import {FormModalBaseComponent} from "../../components/form-modal-base/form-modal-base.component";

@Component({
  selector: 'app-example-form-modal-base',
  templateUrl: './example-form-modal-base.component.html',
})
export class ExampleFormModalBaseComponent extends FormModalBaseComponent {



  example(){

  }
}
