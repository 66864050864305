import {ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ResponseFormat, User} from "../../../../shared/interfaces";
import {EasyApi} from "../../../../services/EasyApi";
import {ErrorPushService} from "../../../../shared/error-push/ErrorPushService";
import {EasyValidatorForm} from "../../../../services/EasyValidatorForm";
import {Router} from "@angular/router";
import {VerificationCodeModel} from "../../../contracts/ContractsAdmin";

@Component({
  selector: 'app-auth-try-admin-addone',
  templateUrl: './auth-try-admin-addone.component.html',
  styleUrls: ['./auth-try-admin-addone.component.scss']
})
export class AuthTryAdminAddoneComponent  {

  @Input()
  public item: User | null = null;

  @Input()
  set itemInput(value: User | null) {
    if (value !== this.item) {
      this.item = value;
      this.Render();
    }
  }

  public list: VerificationCodeModel[] = [];

  constructor(
    private easyValidatorForm: EasyValidatorForm,
    private errorPushService: ErrorPushService,
    private cdr: ChangeDetectorRef,
    private apiService: EasyApi,
    private router: Router
  ) {


  }



  async   Render() {
    var response = await this.apiService.Get<VerificationCodeModel[]>(`/api/AdminAuthTrys/`+(this.item?.id));

    this.list = [];
    console.log(response);

    if (response.value) {
      this.list = response.value;
    }

    this.cdr.detectChanges();
  }
}
