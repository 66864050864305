<tui-island class="mb-1    " *ngIf="member">

  <div class="row">
    <div class="col-6">
      <a href="/user/{{member.accountId}}">

        <div *ngIf="member.account">
          {{member.account.username }}
        </div>

        <div *ngIf="!member.account">
          {{member.id }} Пользователь удален
        </div>
      </a>
    </div>


    <div class="col-6">
      <select [(ngModel)]="member.memberType" (ngModelChange)="onMemberTypeSelected($event)" class="form-control p-2">
        <option *ngFor="let memberType of GetMemberTypeList()" [value]="memberType"  >{{ memberType | MemberTypeNameNumber  }}</option>
      </select>



      <small *ngIf="permMap " style="opacity: 0.7;"><BR>
        *
        {{ listHumanPerms }}
      </small>
    </div>

  </div>
</tui-island>
