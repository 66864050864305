<tui-carousel
  [duration]="4000"
  [draggable]="true"

>

  <tui-island *tuiItem
              style="background: url(/assets/img/grad1.webp) center; background-size: cover; color: #fff;  padding: 10% 80px; margin: 0px; padding-bottom: 0px; height: 400px;">


    <div class="row">

      <div class="col-8">
        <tui-badge
          size="l"
          value="NEW COMPETIOTION"
          class="  mb-3 "
          [hoverable]="true"
        ></tui-badge>


        <h1 class="tui-island__title  " style="font-size: 40px;">Напишите ЛУЧШИЕ топ сервисы CICD Test22 </h1>

        <p class="mt-4">
          Благодаря этому сборнику лучших решений, вам предлагается готовый набор инструментов, который позволяет разрабатывать сервисы более эффективным и эффективным способом.
        </p>

      </div>

      <div class="col">
        X
      </div>
    </div>
  </tui-island>


  <tui-island *tuiItem
              style="background: url(/assets/img/grad2.webp) center; background-size: cover; color: #fff;  padding: 10% 40px;">


    <div class="row">

      <div class="col-8">
        <tui-badge
          size="l"
          value="NEW PROJECT"
          class="  mb-3 "
          [hoverable]="true"
        ></tui-badge>


        <h1 class="tui-island__title  " style="font-size: 40px;">Почему я не поделюсь фреймворком</h1>

        <p class="mt-4">

          Вы сможете использовать проверенные и оптимизированные компоненты для создания различных функциональных возможностей, управления базами данных, создания пользовательского интерфейса, обработки логики и многого другого.

          Кроме того, этот фреймворк предоставляет удобную и интуитивно понятную архитектуру разработки, что упрощает процесс проектирования, разработки и поддержки ваших сервисов. Он позволяет легко интегрировать и расширять функциональность, а также обеспечивает хорошую производительность и надежность.
        </p>

      </div>

      <div class="col">
        X
      </div>
    </div>
  </tui-island>


</tui-carousel>
