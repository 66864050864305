import {Injectable} from "@angular/core";
import {HistoryChatComponent} from "./history-chat/history-chat.component";
import {ChatboxFormChatComponent} from "./chatbox-form-chat/chatbox-form-chat.component";
import {ContactsChatComponent} from "./contacts-chat/contacts-chat.component";

@Injectable({
  providedIn: 'root'
})
export class ChatLinker {
  constructor() {
  }

  public contactsChatComponent: ContactsChatComponent | null = null;
  public historyChatComponent: HistoryChatComponent | null = null;
  public chatboxFormChatComponent: ChatboxFormChatComponent | null = null;

}
