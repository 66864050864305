import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-file-link-downloader',
  templateUrl: './file-link-downloader.component.html',
  styleUrls: ['./file-link-downloader.component.scss']
})
export class FileLinkDownloaderComponent {
  @Input()
  fileExt?: string;

  @Input()
  fileLabel?: string;

  @Input()
  fileName?: string;

  @Input()
  fileFullLink?: string|null;

}
