import {ChangeDetectorRef, Component} from '@angular/core';
import {BaseSearchAdminComponent} from "../../base/BaseSearchAdminComponent";
import {User} from "../../../shared/interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {EasyApi} from "../../../services/EasyApi";

@Component({
  selector: 'app-examples-base-search-admin',
  templateUrl: './examples-base-search-admin.component.html',
  styleUrls: ['./examples-base-search-admin.component.scss']
})
export class ExamplesBaseSearchAdminComponent extends  BaseSearchAdminComponent<User> {

  constructor(router: Router, apiService: EasyApi, cdr: ChangeDetectorRef) {
    super(router, apiService, cdr);
  }

}
