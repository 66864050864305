export interface  Message {
  id: string;
  userId: string;
  receiverMessageId?: string;
  receiverUserId?: string;
  groupId?: string;
  subGroupId?: string;
  createdAt: Date;
  editAt?: Date;
  text: string;
  name: string;
  fileName?: string;
  fileLinkId?: string;
  isRead: boolean;
  isComment: boolean;
  messageType: MessageTypes;
}

export enum MessageTypes {
  Text = 'Text',
  Sticker = 'Sticker',
  File = 'File',
  Voice = 'Voice'
}

// reaction-message.model.ts
export interface ReactionMessage {
  id: string;
  userId: string;
  messageId: string;
  //createdAt: Date;
  reactionId: string;
}

export interface MessageChatRequest {
  ReceiverUserId?: string | null;
  Text: string| null;
  GroupId: string | null;
  SubGroupId?: string | null;
  MessageType: MessageTypes| null;
}


export interface TargetChat {
  ReceiverUserId?: string | null;
  GroupId: string | null;
  SubGroupId: string | null;
}
