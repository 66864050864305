import {AfterViewInit, ChangeDetectorRef, Component, Inject, Injectable, ViewChild} from '@angular/core';
import {User} from "../../../shared/interfaces";
import {Message} from "../ChatModels";
import {EasyApi} from "../../../services/EasyApi";
import {HistoryChatComponent} from "../history-chat/history-chat.component";
import {ChatboxFormChatComponent} from "../chatbox-form-chat/chatbox-form-chat.component";
import {SignalrService} from "../../../services/signalr.service";
import {AuthService} from "../../../services/auth.service";
import {TuiPushService} from "@taiga-ui/kit";
import {map, switchMap, take} from "rxjs";
import {tuiCapitalizeFirstLetter} from "@taiga-ui/core";

@Component({
  selector: 'app-chat-controller',
  templateUrl: './chat-controller.component.html',
  styleUrls: ['./chat-controller.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class ChatControllerComponent {


  public historyChatComponent: HistoryChatComponent | undefined;
  public chatBox: ChatboxFormChatComponent | undefined;
  public user: User | null = null;

  constructor(
    private authService: AuthService,
    private api: EasyApi,
    private cdr: ChangeDetectorRef,
  ) {
    this.user = authService.getCurrentUser();


  }

  OnNewMessage() {


  }

  BtnNotifyClick() {

  }

  SelectContact(selected: User) {
    console.log("SELE");
    if (this.historyChatComponent) {
      this.historyChatComponent.GetHistory(selected);
    }
    if (this.chatBox) {
      this.chatBox.SelectContact(selected);
    }
  }

  SelectMessage(selected: Message) {

  }


}
