<app-admin-layout>


  <tui-loader class="loader"
              [inheritColor]="true"
              [overlay]="true"
              [showLoader]="item==null">


    <div *ngIf="item==null" class="row">
      <div class="col-3">
        <tui-island class=" ">--- загрузка</tui-island>
      </div>
      <div class="col-3">
        <tui-island class=" ">---</tui-island>
      </div>
    </div>


    <div *ngIf="item!=null">


      <div class="row">

        <div class="col-3">
          <tui-island class=" ">
            <p class="tui-island__category">Пользователь</p>
            <h3 class="tui-island__title">{{item.username}}</h3>
            <p class="tui-island__paragraph">
              Не найдено дополнительных данных о пользователи...
            </p>


            <div class="col mt-3">
              <tui-avatar
                size="s"
                [rounded]="true"
                [autoColor]="true"
                [text]="item.username"
                style="margin-top: -2px; margin-right: 6px;"
                class="mr-3   siedeServerIconSize float-start"
              ></tui-avatar>
              {{ item.phone ?? "" | tuiFormatPhone }}

            </div>


          </tui-island>
        </div>
        <div class="col-4">
          <tui-island class=" ">


            Дата регистрации:
            <BR> {{item.createdAt  | date }}

            <BR>
            <BR>
            Дата авторизации: <BR>        {{item.lastOnlineAt | date }}

            <BR>
            <BR>
            Онбординг:     {{item.isOnborded ? 'Пройден' : 'Нет' }}

            <BR>
            <BR>
            Верификация:     {{item.IsVerificated ? 'Выполнена' : 'Не подтверждено' }}


          </tui-island>
        </div>


        <div class="col-5 mt-2">
          <app-auth-try-admin-addone [itemInput]="item"></app-auth-try-admin-addone>
        </div>


        <div class="col-6 mt-2  ">
          <app-admin-addone-user-files [itemInput]="item"></app-admin-addone-user-files>
        </div>


        <div class="col-6  mt-2">
          <app-admin-addone-user-notifys [itemInput]="item"></app-admin-addone-user-notifys>
        </div>

        <div class="col-12 mt-2">
          <app-admin-addone-user-projects [itemInput]="item"></app-admin-addone-user-projects>
        </div>


      </div>


    </div>


  </tui-loader>
</app-admin-layout>
