import {Component} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {User} from "../interfaces";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-user-profile-button',
  templateUrl: './user-profile-button.component.html',
  styleUrls: ['./user-profile-button.component.scss']
})
export class UserProfileButtonComponent {

  user?: User | null;

  constructor(
    private http: HttpClient,
    private router: Router,
    private auth: AuthService
  ) {
    this.isAuth = this.auth.isAuth();

    if (this.isAuth) {
      this.user = this.auth.getCurrentUser();

      if (!this.user) {
        this.auth.getCurrentUserUpdate();
      }
      console.log(this.user);
    }

  }

  isAuth: boolean = false;

  GoToLogin() {

  }

  Logout() {
    this.auth.logout();
    location.href="/";
  }
}
