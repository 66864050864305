export enum MemberTypeEnum {
  Hr,
  Accounting,
  Manager,
  Control,
  Admin,
  Owner,
}


export enum ProjectPermission {
  All,
  UserEdit,
  SetPermissions,
  ReestrCreate,
  ReestrPay,
  ConterpartySet,
  TaxPay,
  StatisticGet,
  ConterpartyGet,
  ReestrGet,
  TaxGet,
  Settings,
}

export class TeamNamingHelper {

  public static PermisiionName(perm: ProjectPermission): string {
    if (perm == ProjectPermission.All) return "Все права";
    if (perm == ProjectPermission.UserEdit) return "Редактирование пользователей";
    if (perm == ProjectPermission.SetPermissions) return "Устанавливать права команды";
    if (perm == ProjectPermission.ReestrCreate) return "Создавать реестр";
    if (perm == ProjectPermission.ReestrPay) return "Проводить реестр";
    if (perm == ProjectPermission.ConterpartySet) return "Редактировать самозанятых";
    if (perm == ProjectPermission.TaxPay) return "Проводить налоги";
    if (perm == ProjectPermission.StatisticGet) return "Смотреть статистику";
    if (perm == ProjectPermission.ConterpartyGet) return "Смотреть справочник самозанятых";
    if (perm == ProjectPermission.ReestrGet) return "Смотреть реестры";
    if (perm == ProjectPermission.TaxGet) return "Смотреть налоги";

    if (perm == ProjectPermission.Settings) return "Настройки проекта";

    return "Право не определено " + perm;
  }

  public static MemberTypeName(perm: MemberTypeEnum): string {
    if (perm == MemberTypeEnum.Owner) return "Владелец";
    if (perm == MemberTypeEnum.Admin) return "Администратор СЗ";
    if (perm == MemberTypeEnum.Accounting) return "Бухгалтер СЗ";
    if (perm == MemberTypeEnum.Manager) return "Менаджер СЗ";
    if (perm == MemberTypeEnum.Control) return "Контроль СЗ";
    if (perm == MemberTypeEnum.Hr) return "HR";
    if (perm == MemberTypeEnum.Admin) return "Админ";


    return "Не определено " + perm;
  }

}
