import {ResponseFormat, User} from "../shared/interfaces";
import {AuthService} from "./auth.service";
import {Inject, Injectable} from "@angular/core";
import {Observable, switchMap, take} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {TuiPushService} from "@taiga-ui/kit";
import {TuiAlertService} from "@taiga-ui/core";
import {ErrorPushService} from "../shared/error-push/ErrorPushService";


@Injectable({
  providedIn: 'root'
})

export class EasyApi {
  constructor(
    private errorService: ErrorPushService,
    private authService: AuthService,
  ) {
  }


  async exampleApi() {

    var resp: ResponseFormat<User> = await this.Get<User>("/api/Project/08dbbf69-da16-4caf-8b04-630324503ce0", true);
    console.log("далее");
    console.log(resp);

  //  this.errorService.showError("Чета там");
  }

  async ApiReuestqHandingWait<T>(req: Observable<any>, isShowAlertError: boolean = false): Promise<ResponseFormat<T>> {

    return new Promise<ResponseFormat<T>>((resolve, reject) => {
      req.subscribe({
        next: (data: ResponseFormat<T>) => {

          resolve(data);
        },
        error: (e: HttpErrorResponse) => {
          console.log(e);

          if (e.error != null) {
            if (e.error.errorMessage != null) {

              if (isShowAlertError) {
                this.errorService.showError(e.error.errorMessage);
              }
              resolve(e.error);
              return;
            }
          }


          var m: string = "Ошибка " + e.statusText + " " + e.status;
          if (e.status == 400) m = "Некорректный запрос";
          if (e.status == 401) m = "Необходима авторизация";
          if (e.status == 403) m = "Доступ запрещен";
          if (e.status == 404) m = "Запрашиваемый ресурс не найден";
          if (e.status == 500) m = "Внутренняя ошибка сервера";

          if (isShowAlertError) {
            this.errorService.showError(m);
          }
          var r = {
            value: null,
            isSuccess: false,
            errorMessage: m
          };
          resolve(r);
        }
      });
    });


  }

  async Get<T>(methodMy: string, isShowAlertError: boolean = false): Promise<ResponseFormat<T>> {
    var req = this.authService.get(methodMy);
    return this.ApiReuestqHandingWait<T>(req , isShowAlertError);
  }

  async Post<T>(methodMy: string, bodyInput: any, isAsForm: boolean = false, isShowAlertError: boolean = false): Promise<ResponseFormat<T>> {
    var body: any = bodyInput;

    if (isAsForm) {
      body = new FormData();
      for (const key in bodyInput) {
        body.append(key, bodyInput[key]);
      }
    }

    var req = this.authService.post(methodMy, body);
    return this.ApiReuestqHandingWait<T>(req, isShowAlertError);
  }


}
