<div class="container p-0 topadmin">
  <div class="row m-0 p-0 ">


    <div class="col-2 p-2">
      <a class="navbar-brand   mb-2 " href="/">
        <img src="/assets/img/logo_c_b.png" height="17" loading="lazy" class="mt-2">
      </a>
    </div>


    <div class="col-auto p-3  " style="padding-right: 0px !important;">
      <a class="btn btn-nevesta-bold p-2" href="/users">
        Пользователи
      </a>
    </div>

    <div class="col-auto p-3  " style="padding-right: 0px !important;">
      <a class="btn btn-nevesta-bold p-2" href="/teams">
        Команды
      </a>
    </div>

    <div class="col-auto p-3  " style="padding-right: 0px !important;">
      <a  class="btn btn-nevesta-bold btn-nevesta-bold-fill p-2" href="/projects">
        Компании
      </a>
    </div>


    <div class="col p-3">

    </div>

    <div class="col-auto p-1  float-end " style="float: right;">
      <app-user-profile-button></app-user-profile-button>
    </div>


  </div>


</div>
<div class="   adminback">
  <div class="container p-0 pt-3  admincontainer">
    <div class="row m-0 p-0  ">

      <ng-content></ng-content>

    </div>
  </div>
</div>


