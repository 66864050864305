import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-addone-project-base',
  templateUrl: './admin-addone-project-base.component.html',
  styleUrls: ['./admin-addone-project-base.component.scss']
})
export class AdminAddoneProjectBaseComponent {

}
