import {ChangeDetectorRef, Component} from '@angular/core';
import {ChatLinker} from "../../chat/ChatLinker";
import {Router} from "@angular/router";
import {EasyApi} from "../../../services/EasyApi";
import {ResponseFormat, User} from "../../../shared/interfaces";
import {Plan} from "../PlanModels";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-plans-page',
  templateUrl: './plans-page.component.html',
  styleUrls: ['./plans-page.component.scss']
})
export class PlansPageComponent {
  private user?: User |null;


  constructor(
    private router: Router,
    private api: EasyApi,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    this.GetPlans();
  }

  plans: Plan[] = [];
  selectd?: Plan | null = null;


  public currentPlanFor?: Date ;
  public currentPlanId:string = "n";

  async GetPlans() {
    this.user = this.authService.getCurrentUser();

    if (this.user) {
      this.currentPlanId = this.user.planId ?? "n";
      this.currentPlanFor = this.user.planFor  ;
    }

    let result: ResponseFormat<Plan[]> = await this.api.Get<Plan[]>("/api/Plan", true);
    this.plans = result.value ?? [];
    this.cdr.detectChanges();
  }

  async Click(plan: Plan) {

    //this.selectd = selected;

    // this.router.navigateByUrl(`/chat/${selected.id}`);
    let result: ResponseFormat<any> = await this.api.Post<any>("/api/Plan/Buy", {PlanId: plan.id}, true);

    if (!result.isSuccess) return;
    if (!result.value) return;
    console.log(result);
    location.href = result.value.urlPay;


  }
}
