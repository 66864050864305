import {Component} from '@angular/core';

@Component({
  selector: 'app-newibie-intro-modal',
  templateUrl: './newibie-intro-modal.component.html',
  styleUrls: ['./newibie-intro-modal.component.scss']
})
export class NewibieIntroModalComponent {
  open = false;

  constructor() {
    const isFirstTime = localStorage.getItem("isFirstTime");

    if (!isFirstTime) {
      this.open = true;
      localStorage.setItem("isFirstTime", "true");
    } else {
      this.open = false;
    }
  }

  index = 0;

  listTutorial: any = [
    {
      img: "url(https://cdn.tvc.ru/pictures/mood/bw/194/22.jpg)",
      title: "Создавай приложения",
      text: "Быстрая разработка и удобные функции",
    },
    {
      img: "url(https://cdn.tvc.ru/pictures/mood/bw/194/22.jpg)",
      title: "Работайте с базами данных",
      text: "Узнайте, как интегрировать и использовать базы данных в вашем приложении",
    },
    {
      img: "#444",
      title: "Оптимизируйте производительность",
      text: "Улучшите производительность вашего приложения с помощью оптимизации кода и запросов",
    }
  ];

  get background(): string {
    return  this.listTutorial[this.index].img;
  }

  navigate(delta: number): void {
    if (this.index == this.listTutorial.length - 1) {
      this.open = false;
    }
    this.index = (this.index + delta) % this.listTutorial.length;

  }
}
