import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {EasyValidatorForm} from "../../../services/EasyValidatorForm";
import {ErrorPushService} from "../../error-push/ErrorPushService";
import {EasyApi} from "../../../services/EasyApi";

@Component({
  selector: 'app-form-modal-base',
  templateUrl: './form-modal-base.component.html',
  styleUrls: ['./form-modal-base.component.scss']
})
export class FormModalBaseComponent {
  @Input()
  public isLoading: boolean = false;

  @Input()
  public open:boolean = false;

  @Input()
  public title: string = "";
  constructor(
    public easyValidatorForm: EasyValidatorForm,
    public errorPushService: ErrorPushService,
    public api: EasyApi,
    public cdr: ChangeDetectorRef
  ) {

  }
}
