import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {FileLinkModel, PlatformProjectContractStatus, Project} from "../../../contracts/ContractsAdmin";
import {EasyValidatorForm} from "../../../../services/EasyValidatorForm";
import {ErrorPushService} from "../../../../shared/error-push/ErrorPushService";
import {EasyApi} from "../../../../services/EasyApi";
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {TUI_ARROW} from "@taiga-ui/kit";

@Component({
  selector: 'app-admin-addone-project-platform-status',
  templateUrl: './admin-addone-project-platform-status.component.html',
})
export class AdminAddoneProjectPlatformStatusComponent {

  public item: Project | null = null;
    IsLoading: boolean = false;

  @Input()
  set itemInput(value: Project | null) {
    if (value !== this.item) {
      this.item = value;
      this.Render();
    }
  }

  public list: FileLinkModel[] = [];

  constructor(
    private easyValidatorForm: EasyValidatorForm,
    private errorPushService: ErrorPushService,
    private cdr: ChangeDetectorRef,
    private apiService: EasyApi,
    private router: Router
  ) {


  }


  async Render() {
    /*
    var response = await this.apiService.Get<FileLinkModel[]>(`/api/admin/AdminFileStorage/ByAccount/` + (this.item?.id));

    this.list = [];
    console.log(response);

    if (response.value) {
      this.list = response.value;
    }

    this.cdr.detectChanges();*/
  }



  readonly statusOptions = [
    PlatformProjectContractStatus.none,
    PlatformProjectContractStatus.review,
    PlatformProjectContractStatus.error,
    PlatformProjectContractStatus.accepted,
  ];


  async SelectedAny(to: PlatformProjectContractStatus) {
    if (!this.item)return;


    console.log('Выбран статус:', to);

    this.IsLoading = true;



    var response = await this.apiService.Post<boolean>(`/api/admin/Project/`+ (this.item?.id)+`/ContractFile/Approve`, {toStatus: to}, true);


    console.log(response);
    console.log(response.value);

    if (!response.isSuccess) {
      console.log("Не принят");
      return;
    }


    this.IsLoading = false;

    this.item.platformContractStatus = to;
    this.cdr.detectChanges();

  }


  readonly arrow = TUI_ARROW;

}

