<tui-island class=" " *ngIf="item!=null">

  <p>
    Файлы <b  style="opacity: 0.5;">( {{list.length}} )</b>
  </p>

  <div *ngIf=" list.length==0">
    Нет файлов
  </div>

  <div *ngFor="let inner of list">


    <tui-island class="mb-1 tui-table__tr_cursor_pointer  ">


      <small>{{inner.name}}</small>
      <BR>
      <b>( {{inner.fileExt}} )</b>
      <small> {{inner.size | number}} мб </small>

      <BR>
      <a href="{{inner.urlFull}}" target="_blank" >
        Скачать
      </a>
    </tui-island>

  </div>

</tui-island>
