<app-admin-layout>


  <tui-island
    size="l"


    style="background:rgba(148,149,154,0.26); background-size: cover; padding: 15px 50px;"
    textAlign="center"
  >


    <h3 class="title">Поиск по командам</h3>
    <form [formGroup]="testForm" (ngSubmit)="Submit()">
      <tui-input
        formControlName="searchInput"
        class="input"
        [tuiTextfieldLabelOutside]="true"
        tuiTextfieldPostfix="      Нажмите Enter"
        [tuiTextfieldSize]="'l'"
        [tuiTextfieldIcon]="'tuiIconSearch'"
      >
        Название, ид
      </tui-input>
      <tui-error
        formControlName="searchInput"
        [error]="[] | tuiFieldError | async"
      ></tui-error>
    </form>
  </tui-island>

  <tui-loader class="loader"
              [inheritColor]="true"
              [overlay]="true"
              [showLoader]="currentList.length==0">


    <div class="row p-3" style=" font-weight: bold; opacity: 0.6;">
      <div class="col">
        Название
      </div>
      <div class="col">
        Дата создания
      </div>
      <div class="col">
        Быстрый доступ
      </div>
    </div>


    <div *ngFor="let item of currentList" (click)="OnClick(item)">

      <tui-island class="mb-1 tui-table__tr_cursor_pointer  ">

        <div class="row " style="padding-left: 12px; font-size: 14px;">

          <div class="col">


            {{item.name ?? "Команда без названия"}}
          </div>
          <div class="col">
            {{item.createdAt  | date }}
          </div>

          <div class="col">
            Быстрый доступ
          </div>
        </div>
      </tui-island>

    </div>


  </tui-loader>
</app-admin-layout>
