<div class="row m-0 p-2">


  <tui-island class="col-2 pt-4 p-0"  >
    <app-sidebasr-content></app-sidebasr-content>
  </tui-island>

  <div class="col">

    <ng-content></ng-content>
  </div>


</div>


