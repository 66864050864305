import { Injectable} from '@angular/core';
import {Observable, tap} from "rxjs";
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor
} from "@angular/common/http";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Router} from "@angular/router";
import {AuthTokentResponse} from "../engine/models/AuthTokentResponse";
import {ResponseFormat, User} from "../shared/interfaces";
import {HttpRequest} from "@angular/common/http";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class AuthService  implements HttpInterceptor{

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("access_token"); // Получите токен аутентификации из вашего хранилища (например, из LocalStorage)

    const modifiedRequest = request.clone({
      headers: request.headers.set(
        'Authorization',
        `Bearer ${token}`
      ),
    });
    return next.handle(modifiedRequest);
  }

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router
  ) {
  }


  saveUser(user: User) {
    localStorage.setItem("accountCurrent", JSON.stringify(user));
  }


  get(methodMy: string) :Observable<any>{
    const header = new HttpHeaders({"Authorization": "Bearer " + localStorage.getItem("access_token")});
    return this.http.get<any>(environment.API_URL + methodMy, {headers: header});
  }

  genHeaderForReq() :HttpHeaders{
   return  new HttpHeaders({"Authorization": "Bearer " + localStorage.getItem("access_token")});
  }
  post(methodMy: string, body: any):Observable<any> {
    const header = this.  genHeaderForReq();
    return this.http.post<any>(environment.API_URL + methodMy, body, {headers: header});
  }

  getCurrentUser():User|null {
    const data = localStorage.getItem("accountCurrent");
    if (data == null) return null;
    return JSON.parse(data);
  }

  getCurrentUserUpdate() {
    const req = this.get("/api/Account/MyAccount");
    req.subscribe({
      next: (data: ResponseFormat<User>) => {

        if (data.value) {
          this.saveUser(data.value);
        }
      },
      error: (e: any) => {
        console.log(e.error);
        return null;
      }
    });

    return req;
  }


  saveToken(token: string) {

    localStorage.setItem("access_token", token);
    this.getCurrentUserUpdate();
  }

  login(login: string, password: string): Observable<ResponseFormat<AuthTokentResponse>> {
    const formData = new FormData();
    formData.append("login", login);
    formData.append("password", password);

    return this.http.post<ResponseFormat<AuthTokentResponse>>(environment.API_URL + "/api/auth/login", formData);
  }


  isAuth(): boolean {
    let token:string|null = localStorage.getItem("access_token");
    if (token == null) return false;
    if (token == "null") return false;
    return !this.jwtHelper.isTokenExpired(token);
  }

  async logout(){
    localStorage.removeItem("access_token");
     await this.router.navigate(['']);
  }

}
