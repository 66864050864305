import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-mini-layout',
  templateUrl: './mini-layout.component.html',
  styleUrls: ['./mini-layout.component.css']
})
export class MiniLayoutComponent {

}
