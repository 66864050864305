import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {EasyApi} from "../../services/EasyApi";

@Component({
  selector: 'app-example',
  templateUrl: './example.component.html',
  styleUrls: ['./example.component.scss']
})
export class ExampleComponent {

  constructor(private  ea:EasyApi) {
     this.ea.exampleApi();
  }
  title = 'EX';
  readonly items = ['Email', 'Phone'];

  type = this.items[0];

  readonly group = new FormGroup({
    name: new FormControl('', Validators.required),
    contact: new FormControl('', Validators.required),
  });

  readonly validator = Validators.email;

}
