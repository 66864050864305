<tui-island class=" " *ngIf="team!=null">

  <p class="tui-island__category">Команда</p>
  <p>
    <b style="opacity: 0.9;"> {{team.name}}</b>

    <BR>
    <a href="/team/{{team.id}}">
      Подробнее
    </a>
  </p>


  <div *ngIf="team.members">
    <p>
      Участников <b style="opacity: 0.5;"> ({{team.members.length}})</b>
    </p>
    <div *ngFor="let inner of team.members ?? []">


      <app-teams-member-element-admin [MemberInput]="inner"></app-teams-member-element-admin>


    </div>
  </div>
</tui-island>
