import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ResponseFormat, User} from "../../shared/interfaces";
import {EasyApi} from "../../services/EasyApi";
import {ActivatedRoute} from "@angular/router";
import {Project} from "../contracts/ContractsAdmin";


@Component({
  selector: 'app-project-admin',
  templateUrl: './project-admin.component.html',
  styleUrls: ['./project-admin.component.scss']
})
export class ProjectAdminComponent implements OnInit {

  public id: string | null = null;
  public item?: Project;
  readonly now = Date.now();

  constructor(
    private api: EasyApi,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef) {
  }



  currentTab:string = "Платформа";
  readonly tabs:string[] = ['Платформа', 'Самозанятые', 'Реестры', 'Команда'];

  async LoadServerId(id: string) {


    let result: ResponseFormat<Project> = await this.api.Get<Project>("/api/admin/AdminProject/" + id, true);
    if (!result.value) return;


    this.item = result.value;
    console.log((this.item));
    console.log((this.item.createdAt));
    this.cdr.detectChanges();

    if (!this.item) {
      console.log("Не найден user " + id);
      return;
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = this.route.snapshot.params["id"];

      this.LoadServerId(this.id ?? "");
    });
  }
}
