import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {User} from "../../../shared/interfaces";
import {Project, TeamModel} from "../../contracts/ContractsAdmin";
import {EasyValidatorForm} from "../../../services/EasyValidatorForm";
import {ErrorPushService} from "../../../shared/error-push/ErrorPushService";
import {EasyApi} from "../../../services/EasyApi";
import {Router} from "@angular/router";

@Component({
  selector: 'app-teams-weight-admin',
  templateUrl: './teams-weight-admin.component.html',
  styleUrls: ['./teams-weight-admin.component.scss']
})
export class TeamsWeightAdminComponent {

  public teamid?: string | null = null;

  @Input()
  set teamidInput(value: string | null) {
    this.teamid="Nul";
    if (value !== this.teamid) {
      this.teamid = value;
      this.Render();
    }
  }

  public team?: TeamModel|null;

  constructor(
    private easyValidatorForm: EasyValidatorForm,
    private errorPushService: ErrorPushService,
    private cdr: ChangeDetectorRef,
    private apiService: EasyApi,
    private router: Router
  ) {


  }


  async Render() {
    var response = await this.apiService.Get<TeamModel>(`/api/team-service/admin/team/` + (this.teamid));

    this.team = null;
    console.log("team:");
    console.log(response);

    if (response.value) {
      this.team = response.value;
    }

    this.cdr.detectChanges();
  }
}
