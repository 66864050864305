<h1 *ngIf="stateForm==AuthStateEnum.Login">Вход</h1>
<h1 *ngIf="stateForm==AuthStateEnum.Registration">Регистрация</h1>
<h1 *ngIf="stateForm==AuthStateEnum.SmsForm">SMS</h1>
<BR>


<tui-loader
  class="loader"
  [inheritColor]="true"
  [overlay]="true"
  [showLoader]="isSending"
>



<tui-notification *ngIf="isSusessfule"
                  status="success"
                  class="tui-space_vertical-4"
>
  Успешно
</tui-notification>

<tui-notification *ngIf="errorMessage!=''"
                  status="error"
                  class="tui-space_vertical-4"
>
  {{errorMessage}}
</tui-notification>

<form
      class="b-form"
      [formGroup]="group"
>


  <tui-input-phone formControlName="phone"  *ngIf="stateForm!=AuthStateEnum.SmsForm" >
    Телефон
    <input
      autocomplete="tel"
      tuiTextfield
    />
  </tui-input-phone>

  <tui-error
    formControlName="phone"
    [error]="[] | tuiFieldError | async"
  ></tui-error>


  <tui-input formControlName="mail"  *ngIf="stateForm==AuthStateEnum.Registration">Почта</tui-input>
  <tui-error
  formControlName="mail"
  [error]="[] | tuiFieldError | async"
></tui-error>


  <ng-container *ngIf="stateForm==AuthStateEnum.SmsForm">
    На номер <b>{{smsVerificationPhoneNumber}}</b> был отправлен СМС код для входа.
  </ng-container>

  <ng-container *ngIf="stateForm==AuthStateEnum.Registration">
    <tui-input formControlName="login" class="tui-space_top-2">Логин</tui-input>
    <tui-error
      formControlName="login"
      [error]="[] | tuiFieldError | async"
    ></tui-error>
  </ng-container>

  <tui-input-password *ngIf="stateForm!=AuthStateEnum.SmsForm"
                      class="tui-space_top-2"
                      formControlName="password">Пароль
  </tui-input-password>
  <tui-error
    formControlName="password"
    [error]="[] | tuiFieldError | async"
  ></tui-error>

  <tui-input-password *ngIf="stateForm==AuthStateEnum.SmsForm"
                      class="tui-space_top-2"
                      formControlName="pin">Код
  </tui-input-password>
  <tui-error
    formControlName="pin"
    [error]="[] | tuiFieldError | async"
  ></tui-error>


  <ng-container *ngIf="stateForm==AuthStateEnum.SmsForm">
    <button
      appearance="primary"
      tuiButton
      type="button"
      class="tui-space_right-3 tui-space_bottom-3 mt-2 w-100"
      (click)="ClickSendPin()"
    >
      Отправить
    </button>

  </ng-container>

  <ng-container *ngIf="stateForm==AuthStateEnum.Registration">
    <button
      appearance="primary"
      tuiButton
      type="button"
      class="tui-space_right-3 tui-space_bottom-3 mt-2 w-100"
      (click)="ClickSendReg()"
    >
      Продолжить
    </button>

  </ng-container>

  <ng-container *ngIf="stateForm==AuthStateEnum.Login">
    <button
      appearance="primary"
      tuiButton
      type="button"
      class="tui-space_right-3 tui-space_bottom-3 mt-2 w-100"
      (click)="ClickSendAuth()"
    >
      Войти
    </button>

  </ng-container>

</form>

<ng-container *ngIf="isLoginWait">



<div class="w-100 text-center">
  <a tuiLink (click)="TogleMode()" *ngIf="stateForm==AuthStateEnum.Registration">У меня есть аккаунт</a>
  <a tuiLink (click)="TogleMode()" *ngIf="stateForm==AuthStateEnum.Login">Создать новый аккаунт</a>
</div>



  <BR>

  <div class="text-center">
    ИЛИ
  </div>
  <BR>
  <ng-container >
    <button
      class="w-100 mb-3 "
      tuiButton
      appearance="outline"
      type="button"
      (click)="ClickGoogle()"
    >
      <svg style="height: 23px; margin-right: 10px;" viewBox="0 0 1152 1152" focusable="false" aria-hidden="true"
           role="img" class="spectrum-Icon spectrum-Icon--sizeM" data-social-button-type="icon"
           data-social-white-background="true">
        <path
          d="M1055.994 594.42a559.973 559.973 0 0 0-8.86-99.684h-458.99V683.25h262.28c-11.298 60.918-45.633 112.532-97.248 147.089v122.279h157.501c92.152-84.842 145.317-209.78 145.317-358.198z"
          fill="#4285f4"></path>
        <path
          d="M588.144 1070.688c131.583 0 241.9-43.64 322.533-118.07l-157.5-122.28c-43.64 29.241-99.463 46.52-165.033 46.52-126.931 0-234.368-85.728-272.691-200.919H152.636v126.267c80.19 159.273 245 268.482 435.508 268.482z"
          fill="#34a853"></path>
        <path d="M315.453 675.94a288.113 288.113 0 0 1 0-185.191V364.482H152.636a487.96 487.96 0 0 0 0 437.724z"
              fill="#fbbc05"></path>
        <path
          d="M588.144 289.83c71.551 0 135.792 24.589 186.298 72.88l139.78-139.779C829.821 144.291 719.504 96 588.143 96c-190.507 0-355.318 109.21-435.508 268.482L315.453 490.75c38.323-115.19 145.76-200.919 272.691-200.919z"
          fill="#ea4335"></path>
      </svg>
      Продолжить через Google
    </button>


    <button
      class="w-100"
      tuiButton
      icon="tuiIconGitPullRequestLarge"
      appearance="outline"
      type="button"
      (click)="ClickGoogle()"
    >
      Продолжить через Apppertine
    </button>

  </ng-container>

  <BR>
  <BR>

  <ng-container *ngIf="stateForm==AuthStateEnum.Registration">
    <small style="opacity: 0.8;">Нажимая «Продолжить», вы принимаете и соглашаетесь на обработку
      вашей персональной
      информации на <a href="/privacy"> условиях политики конфиденциальности </a></small>
  </ng-container>

  <ng-container *ngIf="stateForm==AuthStateEnum.Login">
    <small style="opacity: 0.8;">Нажимая «Войти», вы принимаете и соглашаетесь на обработку
      вашей персональной
      информации на <a href="/privacy"> условиях политики конфиденциальности </a></small>

  </ng-container>
</ng-container>

</tui-loader>
