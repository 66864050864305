import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TuiCurrency} from '@taiga-ui/addon-commerce';
import {TuiDay, TuiTime} from '@taiga-ui/cdk';
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {ResponseFormat} from "../../../shared/interfaces";
import {AuthTokentResponse} from "../../../engine/models/AuthTokentResponse";

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent {


  private _authService: AuthService;

  constructor(authService: AuthService,   private router: Router) {

    this._authService = authService;
  }


  OnPayMake(){
    var amount: any = this.payForm.value['amount'];

    const formData = new FormData();
    formData.append("amount", amount);

    this._authService.post("/api/Payment/CreatePay", formData).subscribe({
      next: (data: ResponseFormat<any>) => {
        location.href = data.value.urlPay;
      },
      error: (e: any) => {
        console.log(e);
        console.log(e.error);
       // this.ErrorHanding(e.error);
      }
    });
  }

  payForm = new FormGroup({
    amount: new FormControl(4, Validators.required),
  });
}
