import {Component, Input} from '@angular/core';
import {Plan} from "../PlanModels";
import {Router} from "@angular/router";
import {PlansPageComponent} from "../plans-page/plans-page.component";

@Component({
  selector: 'app-element-plan',
  templateUrl: './element-plan.component.html',
  styleUrls: ['./element-plan.component.scss']
})
export class ElementPlanComponent {

  @Input()
  public plan?: Plan;

  constructor(
    public plansPage: PlansPageComponent
  ) {
  }
  public colorList = ["#5865F2", "#36393F", "#C82AC3", "#3BA55C"];


  IsNotEmptyDetail(val: string): boolean {
    const nonEmptyValues = ["0", "-", "нет", "запрещено", "отключено", "нету"];

    return !nonEmptyValues.includes(val.toLowerCase());
  }

  Click() {
    if (!this.plan) return;
    this.plansPage.Click(this.plan);
  }
}
