<ng-template
  let-observer
  [tuiDialogOptions]="{  size: 's'}"
  [(tuiDialog)]="open"
>
  <tui-loader
    class="loader"
    [inheritColor]="true"
    [overlay]="true"
    [showLoader]="isLoading"
  >

    <h1 *ngIf="title!=''">{{title}}</h1>

    <ng-content></ng-content>
  </tui-loader>
</ng-template>


