<tui-island class=" " *ngIf="item!=null">

  <p>
    Компании <b  style="opacity: 0.5;">( {{list.length}} )</b>
  </p>

  <div *ngIf=" list.length==0">
    Нет компаний
  </div>

  <div *ngFor="let inner of list">


    <tui-island class="mb-1 tui-table__tr_cursor_pointer  ">
      {{inner.id }}

      <BR>
      <small>{{inner.name}}</small> -

      <a href="/projects/{{inner.id}}">
        Перейти
      </a>
    </tui-island>

  </div>

</tui-island>
