
  <ng-container *ngIf="!isAuth">
    <a
      tuiLink
      href="/login"

    > Войти </a>
  </ng-container>


  <div class="row" *ngIf="isAuth && user!=null">



      <tui-avatar
        size="s"
        class="col-3 mt-1"
[autoColor]="true"
        text="{{user.email}}"
        [rounded]="true"
      ></tui-avatar>

    <div class="col   pt-0">

      {{user.email}}
      <BR>

      КМ



    </div>


    <tui-hosted-dropdown

      class="col-2  float-end pt-2"
      [content]="dropdownContent"
    >
      <button
        size="s"
        appearance="whiteblock"
        icon="tuiIconMoreVertical"
        tuiIconButton
        type="button"
      >X
      </button>


    </tui-hosted-dropdown>


  </div>


  <ng-template #dropdownContent>
    <tui-data-list tuiDataListDropdownManager>


      <a
        class="tui-table__tr_cursor_pointer link-dark"
        tuiOption
        (click)="Logout()"> Выход </a>

    </tui-data-list>
  </ng-template>
