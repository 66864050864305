import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ExampleComponent} from "./page/example/example.component"
import {AuthComponent} from "./page/auth/auth.component";
import {IndexComponent} from "./page/index/index.component";
import {ProfileComponent} from "./page/profile/profile.component";
import {AuthGuard} from "./shared/services/auth.guard";
import {PaymentFormComponent} from "./plugins/payment/payment-form/payment-form.component";
import {PaymentSuccessComponent} from "./plugins/payment/payment-success/payment-success.component";
import {PaymentFailComponent} from "./plugins/payment/payment-fail/payment-fail.component";
import {NotFoundComponentComponent} from "./engine/pages/not-found-component/not-found-component.component";
import {PrivatPolicePageComponent} from "./engine/pages/privat-police-page/privat-police-page.component";
import {SignalTutorialComponent} from "./examples/signal-tutorial/signal-tutorial.component";
import {ChatPageComponent} from "./page/chat-page/chat-page.component";
import {ChatRoutes} from "./plugins/chat/ChatRouter";
import {PlanRoutes} from "./plugins/plan/PlanRoutes";
import {UsersAdminComponent} from "./admin/users-admin/users-admin.component";
import {UserAdminComponent} from "./admin/user-admin/user-admin.component";
import {ProjectAdminComponent} from "./admin/project-admin/project-admin.component";
import {ProjectsAdminComponent} from "./admin/projects-admin/projects-admin.component";
import {TeamsSearchAdminComponent} from "./admin/team/teams-search-admin/teams-search-admin.component";


const routes: Routes = [
  ...ChatRoutes,
  ...PlanRoutes,

  {path: '', component: UsersAdminComponent, canActivate: [AuthGuard] },

  {path: 'users', component: UsersAdminComponent, canActivate: [AuthGuard]},
  {path: 'user/:id', component: UserAdminComponent, canActivate: [AuthGuard]},
  {path: 'projects', component: ProjectsAdminComponent, canActivate: [AuthGuard]},
  {path: 'projects/:id', component: ProjectAdminComponent, canActivate: [AuthGuard]},

  {path: 'teams', component: TeamsSearchAdminComponent, canActivate: [AuthGuard]},
  {path: 'teams/:id', component: TeamsSearchAdminComponent, canActivate: [AuthGuard]},

  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
  {path: 'login', component: AuthComponent},
  {path: 'example', component: ExampleComponent, canActivate: [AuthGuard]},


  {path: 'payment', component: PaymentFormComponent, canActivate: [AuthGuard]},
  {path: 'payment/success', component: PaymentSuccessComponent, canActivate: [AuthGuard]},
  {path: 'payment/fail', component: PaymentFailComponent, canActivate: [AuthGuard]},


  {path: 'privacy', component: PrivatPolicePageComponent },

  {path: 'signal', component: SignalTutorialComponent},

  {path: '**', component: NotFoundComponentComponent},

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
