import { Component } from '@angular/core';

@Component({
  selector: 'app-with-sidebar',
  templateUrl: './with-sidebar.component.html',
  styleUrls: ['./with-sidebar.component.scss']
})
export class WithSidebarComponent {


}
