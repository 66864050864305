<app-container-layout>

  <app-chat-controller>
    <div class="row">
      <div class="col-3">
        <app-contacts-chat />
      </div>
      <div class="col">

        <div class="col-12">
          <app-history-chat  />
        </div>

        <div class="col-12">
          <app-chatbox-form-chat/>
        </div>

      </div>
    </div>

    <app-signal-notify-chat/>
  </app-chat-controller>

</app-container-layout>
