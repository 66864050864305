<app-mini-layout>

  <tui-loader class="loader"
              [inheritColor]="true"
              [overlay]="true"
              [showLoader]="isLoading">


    <div class="text-center p-4" *ngIf="isLoading">
      Проверка платежа
    </div>


    <div class="text-center p-4" *ngIf="!isLoading">
      <div class="mb-5">
        <i class="fas fa-check-circle text-success fa-5x"></i>
      </div>

      <ng-container *ngIf="isTarifMode">
        <h1 class="mb-4">Тариф оплачен!</h1>

        <h5 class="text-muted mb-4">Спасибо за приобретение тарифа!</h5>
        <p class="lead">
          Ваш баланс был успешно пополнен. Теперь вы можете наслаждаться всеми возможностями и преимуществами нашей
          платформы.
        </p>
      </ng-container>

      <ng-container *ngIf="!isTarifMode">
        <h1 class="mb-4">Баланс успешно пополнен</h1>
        <tui-money
          [colored]=true
          class="large"
          [value]="balance"
        ></tui-money>

        <h5 class="text-muted mb-4">Спасибо за пополнение счета!</h5>
        <p class="lead">
          Вы успешно приобрели тариф. Теперь вы можете  пользоваться всеми функциями и преимуществами нашей платформы!
        </p>
      </ng-container>

      <a href="/" tuiButton appearance="primary" class="btn btn-primary mt-3">Перейти на главную</a>


    </div>

  </tui-loader>
</app-mini-layout>
