import {NgDompurifySanitizer} from "@tinkoff/ng-dompurify";
import {
  TuiRootModule,
  TuiDialogModule,
  TuiAlertModule,
  TUI_SANITIZER,
  TuiTextfieldControllerModule,
  TuiHostedDropdownModule,
  TuiSvgModule,
  TuiButtonModule,
  TuiNotificationModule,
  TuiDropdownModule,
  TuiDataListModule,
  TuiThemeNightModule,
  TuiModeModule,
  TuiLinkModule,
  TuiTooltipModule,
  TuiErrorModule, TuiLoaderModule
} from "@taiga-ui/core";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  TuiAvatarModule, TuiBadgedContentModule,
  TuiBadgeModule, TuiCarouselModule, TuiDataListDropdownManagerModule,
  TuiDataListWrapperModule, TuiFieldErrorPipeModule, TuiFilterByInputPipeModule,
  TuiInputModule, TuiInputPasswordModule,
  TuiInputPhoneModule, TuiInputSliderModule,
  TuiIslandModule, TuiLineClampModule, TuiPaginationModule, TuiProgressModule, TuiPushModule,
  TuiSelectModule
} from "@taiga-ui/kit";
import {TuiMoneyModule} from '@taiga-ui/addon-commerce';
import {TuiValidatorModule} from '@taiga-ui/cdk';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ExampleComponent} from './page/example/example.component';
import {TuiBlockStatusModule} from '@taiga-ui/layout';
import {RouterModule} from "@angular/router";
import {AuthComponent} from './page/auth/auth.component';
import {ProfileComponent} from './page/profile/profile.component';
import {IndexComponent} from './page/index/index.component';
import {CaruselIndexInfoComponent} from './shared/carusel-index-info/carusel-index-info.component';

import {JwtHelperService, JwtModule, JWT_OPTIONS, JwtModuleOptions} from "@auth0/angular-jwt";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {UserProfileButtonComponent} from './shared/user-profile-button/user-profile-button.component';
import {PaymentSuccessComponent} from './plugins/payment/payment-success/payment-success.component';
import {PaymentFailComponent} from './plugins/payment/payment-fail/payment-fail.component';
import {PaymentFormComponent} from './plugins/payment/payment-form/payment-form.component';
import {AuthFormComponent} from './plugins/auth-form/auth-form.component';
import {ErrorPushComponent} from './shared/error-push/error-push.component';
import {NewibieIntroModalComponent} from './plugins/newibie-intro-modal/newibie-intro-modal.component';
import {FormModalBaseComponent} from './shared/components/form-modal-base/form-modal-base.component';
import {
  ExampleFormModalBaseComponent
} from './shared/examples/example-form-modal-base/example-form-modal-base.component';
import {ContainerLayoutComponent} from "./engine/layouts/container-layout/container-layout.component";
import {MiniLayoutComponent} from "./engine/layouts/mini-layout/mini-layout.component";
import {NotFoundComponentComponent} from "./engine/pages/not-found-component/not-found-component.component";
import {WithSidebarComponent} from "./engine/layouts/with-sidebar/with-sidebar.component";
import {SidebasrContentComponent} from "./engine/layouts/sidebasr-content/sidebasr-content.component";
import {PrivatPolicePageComponent} from "./engine/pages/privat-police-page/privat-police-page.component";
import {AuthService} from "./services/auth.service";
import {SignalStaterComponent} from './engine/components/signal-stater/signal-stater.component';
import {SignalTutorialComponent} from './examples/signal-tutorial/signal-tutorial.component';
import {ChatboxFormChatComponent} from './plugins/chat/chatbox-form-chat/chatbox-form-chat.component';
import {ReactionFromChatComponent} from './plugins/chat/reaction-from-chat/reaction-from-chat.component';
import {HistoryChatComponent} from './plugins/chat/history-chat/history-chat.component';
import {ContactsChatComponent} from './plugins/chat/contacts-chat/contacts-chat.component';
import {ChatControllerComponent} from './plugins/chat/chat-controller/chat-controller.component';
import {ChatPageComponent} from './page/chat-page/chat-page.component';
import {TuiScrollbarModule} from "@taiga-ui/core";
import {TuiTextareaModule} from "@taiga-ui/kit";
import { SignalNotifyChatComponent } from './plugins/chat/signal-notify-chat/signal-notify-chat.component';
import {environment} from "../environments/environment";
import {AuthStateEnum} from "./plugins/auth-form/auth-contracts";

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter,
    allowedDomains: [ environment.API_DOMAIN],
    // allowedDomains:
    //whitelistedDomains: yourWhitelistedDomains
  }
};

export const EvolonaCoreModules = {
  declarations: [
    AppComponent,
    ExampleComponent,
    ContainerLayoutComponent,
    MiniLayoutComponent,
    NotFoundComponentComponent,
    AuthComponent,
    WithSidebarComponent,
    ProfileComponent,
    IndexComponent,
    CaruselIndexInfoComponent,
    SidebasrContentComponent,
    UserProfileButtonComponent,
    PaymentSuccessComponent,
    PaymentFailComponent,
    PaymentFormComponent,
    AuthFormComponent,
    ErrorPushComponent,
    PrivatPolicePageComponent,
    NewibieIntroModalComponent,
    FormModalBaseComponent,
    ExampleFormModalBaseComponent,
    SignalStaterComponent,
    SignalTutorialComponent,
    ChatboxFormChatComponent,
    ReactionFromChatComponent,
    HistoryChatComponent,
    ContactsChatComponent,
    ChatControllerComponent,
    ChatPageComponent,
    SignalNotifyChatComponent,
  ],

  imports: [
    ReactiveFormsModule,
    TuiSvgModule,
    TuiBlockStatusModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    TuiRootModule,
    TuiDialogModule,
    TuiAlertModule,
    TuiTextfieldControllerModule,
    TuiInputModule,
    TuiValidatorModule,
    TuiMoneyModule,
    TuiSelectModule,
    TuiDataListWrapperModule,
    TuiInputPhoneModule,
    TuiIslandModule,
    TuiInputPasswordModule,
    TuiBadgeModule,
    TuiAvatarModule,
    TuiBadgedContentModule,
    TuiHostedDropdownModule,
    TuiDataListDropdownManagerModule,
    TuiCarouselModule,
    TuiPaginationModule,
    TuiButtonModule,
    HttpClientModule,
    TuiThemeNightModule,
    TuiModeModule,
    JwtModule,
    TuiNotificationModule,
    JwtModule.forRoot(JWT_Module_Options),
    TuiDropdownModule,
    TuiDataListModule,
    TuiLinkModule,
    TuiProgressModule,
    TuiTooltipModule,
    TuiLineClampModule,
    TuiFieldErrorPipeModule,
    TuiInputSliderModule,
    TuiErrorModule,
    TuiPushModule,
    TuiLoaderModule,
    TuiScrollbarModule,
    TuiTextareaModule,
    TuiFilterByInputPipeModule,
  ],

  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthService, multi: true},
    {provide: JWT_OPTIONS, useValue: JWT_OPTIONS},
    JwtHelperService,
    {provide: TUI_SANITIZER, useClass: NgDompurifySanitizer}
  ],
  bootstrap: [AppComponent]
};
