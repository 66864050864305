import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {EasyApi} from "../../services/EasyApi";
import {ActivatedRoute} from "@angular/router";
import {ResponseFormat, User} from "../../shared/interfaces";

@Component({
  selector: 'app-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss']
})
export class UserAdminComponent implements OnInit {

  public id: string | null = null;
  public item?: User;
  readonly now = Date.now();

  constructor(
    private api: EasyApi,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef) {
  }


  async LoadServerId(id: string) {


    let result: ResponseFormat<User> = await this.api.Get<User>("/api/AdminAccount/" + id, true);
    if (!result.value) return;


    this.item = result.value;
    console.log((this.item));
    console.log((this.item.createdAt));
    this.cdr.detectChanges();

    if (!this.item) {
      console.log("Не найден user " + id);
      return;
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = this.route.snapshot.params["id"];

      this.LoadServerId(this.id ?? "");
    });
  }
}
