import {ChangeDetectorRef, Component, Injectable} from '@angular/core';
import {ResponseFormat, User} from "../../../shared/interfaces";
import {EasyApi} from "../../../services/EasyApi";
import {ChatControllerComponent} from "../chat-controller/chat-controller.component";
import {Message, MessageChatRequest, MessageTypes, TargetChat} from "../ChatModels";
import {FormControl, Validators} from "@angular/forms";
import {ChatLinker} from "../ChatLinker";

@Component({
  selector: 'app-chatbox-form-chat',
  templateUrl: './chatbox-form-chat.component.html',
  styleUrls: ['./chatbox-form-chat.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class ChatboxFormChatComponent {
  constructor(
    private chatLinker: ChatLinker,
    private api: EasyApi,
    private controller: ChatControllerComponent,
    private cdr: ChangeDetectorRef
  ) {
    controller.chatBox = this;
    chatLinker.chatboxFormChatComponent=this;
  }

  contact: User | null = null;
  targetChat: TargetChat | null = null;

  SelectContact(selected: User) {
    this.contact = selected;

    this.targetChat = {
      GroupId: null,
      ReceiverUserId: selected.id,
      SubGroupId: null
    }
  }

  SelectContactExtend(targetChat: TargetChat) {
    this.targetChat = targetChat;
  }

  textarea: FormControl = new FormControl('', [Validators.required]);

  async SendMessage() {


    if (! this.targetChat)return;

    let data :any= {
      Text: this.textarea.value,
      MessageType: MessageTypes.Text,
    };
    data.Text = data.Text.trim();
    if (data.Text.length<1)return;

    if (this.targetChat.ReceiverUserId)data.ReceiverUserId = this.targetChat?.ReceiverUserId;
    if (this.targetChat.GroupId)data.GroupId = this.targetChat?.GroupId;
    if (this.targetChat.SubGroupId)data.SubGroupId = this.targetChat?.SubGroupId;

   // console.log(data);
    this.textarea.setValue("");

    let result: ResponseFormat<any> = await this.api.Post<any>("/api/Chat/SendMessage", data, true, true);
   // console.log(result);

    if (result.value != null) {

      let itemMessage: Message = {
        id: result.value,
        userId: "asf",
        //receiverUserId: this.targetChat.ReceiverUserId ,
        name: "Вы",
        text: data.Text ?? "",
        createdAt: new Date(),
        isRead: false,
        isComment: false,
        messageType: MessageTypes.Text,
      };

      if ( this.targetChat.ReceiverUserId){
        itemMessage.receiverUserId=  this.targetChat.ReceiverUserId;
      }


      if (!this.chatLinker.historyChatComponent)  return;
      this.chatLinker.historyChatComponent.AddMessage(itemMessage);
    }

  }

}
