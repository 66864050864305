import {ChangeDetectorRef, Component, Injectable} from '@angular/core';
import {EasyApi} from "../../../services/EasyApi";
import {ResponseFormat, User} from "../../../shared/interfaces";
import {ChatControllerComponent} from "../chat-controller/chat-controller.component";
import {Router} from "@angular/router";
import {ChatLinker} from "../ChatLinker";

@Component({
  selector: 'app-contacts-chat',
  templateUrl: './contacts-chat.component.html',
  styleUrls: ['./contacts-chat.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class ContactsChatComponent {


  constructor(
    private chatLinker: ChatLinker,
    private router: Router,
    private api: EasyApi,
    private  cdr: ChangeDetectorRef
  ) {
    chatLinker.contactsChatComponent = this;
    this.GetContacts();
  }

  contacts: User[] =[];
  selectd?: User | null = null;


  ClickContact(selected: User) {
    this.selectd = selected;

    this.router.navigateByUrl(`/chat/${selected.id}`);
  }

  async GetContacts() {
    let result: ResponseFormat<User[]> = await this.api.Get<User[]>("/api/Chat/Contacts", true);
    this.contacts = result.value ?? [];
    this.cdr.detectChanges();
  }

}
